import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';
import { OpenSeaIcon } from '@mp-frontend/core-components/icons';

import LoginRequiredButton from 'components/LoginRequiredButton';
import { useTrackingContext } from 'components/trackingContext';
import GTM from 'GTM';
import { useLockProduct } from 'hooks/product/useLockProduct';
import useProductOwnership from 'hooks/product/useProductOwnership';
import useProductState, {
  ProductStateProps,
} from 'hooks/product/useProductState';
import { LinkComponent } from 'pages/drops/components/moreInfoDialog/Links';
import CSSMargin from 'types/enums/css/Margin';
import hasDatePassed from 'utils/hasDatePassed';
import {
  NFTActionType,
  nftHasFinalizingProductAuction,
  nftHasFinalizingRankedAuction,
} from 'utils/nftUtils';

import PurchaseOfferDialog from '../purchaseOfferDialog';
import ProductNotLiveActions from './ProductNotLiveActions';

export interface ProductActionsProps extends ProductStateProps {
  invalidate: () => void;
  openSeaUrl?: string;
}

export default function ProductActions({
  nft,
  openSeaUrl,
  showDropsTimer,
  showProductAuctionTimer,
  showRankedAuctionTimer,
  invalidate,
}: ProductActionsProps) {
  const productId = parseInt(nft.listing.pk, 10);
  const { source } = useTrackingContext();

  const {
    canBuy,
    canOffer,
    isPendingSale,
    showPresale,
    isPresaleActive,
    isPresaleEligible,
    showTimer,
  } = useProductState({
    nft,
    showDropsTimer,
    showProductAuctionTimer,
    showRankedAuctionTimer,
  });
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const [isReserveLocked, reserveLockEndDate, lock, unlock] = useLockProduct(
    productId,
    isPresaleActive && isPresaleEligible
  );
  const [purchaseOfferDialogType, setPurchaseOfferDialogType] =
    useState<NFTActionType>(NFTActionType.DEFAULT);

  const openPurchaseOfferDialog = useCallback(
    async (type: NFTActionType) => {
      if (type === NFTActionType.BUY) {
        try {
          await lock();
          GTM.ecommerce.beginCheckout(nft, source);
        } catch {
          return;
        }
      }

      setPurchaseOfferDialogType(type);
    },
    [nft, lock, source]
  );

  const closePurchaseOfferDialog = useCallback(async () => {
    if (
      purchaseOfferDialogType === NFTActionType.BUY &&
      !hasDatePassed(reserveLockEndDate)
    ) {
      await unlock().catch(() => {});
    }

    setPurchaseOfferDialogType(NFTActionType.DEFAULT);
  }, [purchaseOfferDialogType, reserveLockEndDate, unlock]);

  if (isCurrentUserTokenOwner || isPendingSale) return null;

  return (
    <>
      {purchaseOfferDialogType ? (
        <PurchaseOfferDialog
          nft={nft}
          type={purchaseOfferDialogType}
          reserveLockEndDate={reserveLockEndDate}
          isReserved={isReserveLocked}
          invalidate={invalidate}
          onClose={closePurchaseOfferDialog}
        />
      ) : null}

      {!canBuy &&
      !canOffer &&
      !!nft.metadata.dropMetadata &&
      !!nft.onchainId ? (
        <ProductNotLiveActions
          dropMetadata={nft.metadata.dropMetadata}
          dropsAt={nft.metadata.dropMetadata.dropsAt}
          forceUpdate={invalidate}
          isAuctionFinalizing={
            nftHasFinalizingProductAuction(nft) ||
            nftHasFinalizingRankedAuction(nft)
          }
          isPresaleActive={isPresaleActive}
          isPresaleEligible={isPresaleEligible}
          showPresale={showPresale}
          onPresalePurchaseClick={() =>
            openPurchaseOfferDialog(NFTActionType.BUY)
          }
        />
      ) : (
        <>
          {!!canBuy && (
            <LoginRequiredButton
              fullWidth
              size="large"
              onClick={() => openPurchaseOfferDialog(NFTActionType.BUY)}
            >
              Purchase
            </LoginRequiredButton>
          )}
          {!!canOffer && (
            <LoginRequiredButton
              variant={canBuy ? 'secondary' : 'primary'}
              fullWidth
              size="large"
              onClick={() => openPurchaseOfferDialog(NFTActionType.OFFER)}
            >
              {showTimer ? 'Place a Bid' : 'Make an Offer'}
            </LoginRequiredButton>
          )}
          {!!openSeaUrl && (
            <MPActionButton
              variant="secondary"
              fullWidth
              size="large"
              href={openSeaUrl}
              LinkComponent={LinkComponent}
              onClick={() =>
                GTM.pdp.trackOpenSeaCtaClick(nft.listing?.productSlug)
              }
              startIcon={<OpenSeaIcon fontSize="18" />}
            >
              <span className={CSSMargin.LEFT[2]}>View on OpenSea</span>
            </MPActionButton>
          )}
        </>
      )}
    </>
  );
}
