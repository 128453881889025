/**
 * @generated SignedSource<<f4507adbf9eb16b24ddaf67ce65cf3bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfilesQuery$variables = {
  slug: string;
};
export type ProfilesQuery$data = {
  readonly profile: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfilesFragment">;
  } | null;
};
export type ProfilesQuery = {
  variables: ProfilesQuery$variables;
  response: ProfilesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "globalSlug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalItems",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "FloorPriceNode",
  "kind": "LinkedField",
  "name": "floorPrice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPriceInEth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPriceInUsd",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "categorySlug",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProfileType",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfilesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProfileType",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCurrentUser",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLikes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfOwners",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalExhibitions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TotalVolumeNode",
            "kind": "LinkedField",
            "name": "totalVolume",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalVolumeInEth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalVolumeInUsd",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ListingStatsType",
            "kind": "LinkedField",
            "name": "listingStats",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aboutBanner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateJoined",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCollectedDigitalMedias",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreLinkType",
            "kind": "LinkedField",
            "name": "socialLinks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "linkType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "curator",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreCategoryType",
            "kind": "LinkedField",
            "name": "defaultCategory",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreCategoryType",
            "kind": "LinkedField",
            "name": "defaultCollection",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pk",
                "storageKey": null
              },
              (v6/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "etherscanUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "openseaUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfItemsListed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DropFamilyTypeV2",
            "kind": "LinkedField",
            "name": "drop",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "heroDescription",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54fdd5e439c1f7c22b947b15cbcee200",
    "id": null,
    "metadata": {},
    "name": "ProfilesQuery",
    "operationKind": "query",
    "text": "query ProfilesQuery(\n  $slug: String!\n) {\n  profile(globalSlug: $slug) {\n    ...ProfilesFragment\n    id\n  }\n}\n\nfragment ProfilesFragment on ProfileType {\n  id\n  address\n  description\n  shortDescription\n  isCurrentUser\n  profileImageUrl\n  hasLikes\n  accountType\n  numOfOwners\n  totalItems\n  totalExhibitions\n  totalVolume {\n    totalVolumeInEth\n    totalVolumeInUsd\n  }\n  floorPrice {\n    floorPriceInEth\n    floorPriceInUsd\n  }\n  listingStats {\n    totalItems\n    floorPrice {\n      floorPriceInEth\n      floorPriceInUsd\n    }\n  }\n  aboutBanner\n  dateJoined\n  displayName\n  fullName\n  hasCollectedDigitalMedias\n  slug\n  socialLinks {\n    id\n    linkType\n    value\n  }\n  curator {\n    id\n    username\n    fullName\n  }\n  defaultCategory {\n    id\n    categorySlug\n  }\n  defaultCollection {\n    id\n    categorySlug\n  }\n  user {\n    id\n    pk\n    fullName\n    username\n    profileImageUrl\n  }\n  etherscanUrl\n  openseaUrl\n  numOfItemsListed\n  drop {\n    slug\n    heroDescription\n  }\n}\n"
  }
};
})();

(node as any).hash = "dcdfeaed74276631a36058e01da86d28";

export default node;
