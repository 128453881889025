.searchPage {
  --searchpage-gap: 60px;
  --searchpage-leftMargin: 32px;
  --searchpage-rightMargin: 16px;

  @media screen and (min-width: 1920px) {
    --searchpage-leftMargin: 112px;
    --searchpage-rightMargin: 96px;
  }

  @media (--mq-is-mobile) {
    --searchpage-gap: 32px;
    --searchpage-leftMargin: 0px;
    --searchpage-rightMargin: 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--searchpage-gap);
  margin: 0 auto var(--searchpage-gap) auto;
  max-width: 1440px;

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }
}

.content > section {
  margin: 0 var(--searchpage-rightMargin) 0 var(--searchpage-leftMargin);
}
