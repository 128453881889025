.container {
  padding: 0 32px 0 48px;
  max-width: 1920px;
  margin: auto;

  @media (--mq-is-desktop) {
    max-width: 1440px;
  }

  @media (--mq-is-mobile) {
    max-width: 100%;
    padding: 0 24px;
  }
}
