import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import MPStandardDialog, {
  MPStandardDialogProps,
} from '@mp-frontend/core-components/src/dialog/MPStandardDialog';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/dialogs/StackStateDialog.module.css';

const StackContext = createContext(undefined);

interface StackState {
  actionButtonRef: MutableRefObject<HTMLDivElement>;
  childrenRef: MutableRefObject<HTMLDivElement>;
  setNext: Dispatch<StackState>;
  title: string;
  next?: StackState;
  onClose?: () => void;
}

function useStackContext(title, onClose) {
  const contextStack = useContext(StackContext);
  const stack = useMemo<StackState>(() => ({} as any), []);
  const [next, setNext] = useState<StackState>();
  stack.next = next;
  stack.setNext = setNext;
  stack.title = title;
  stack.onClose = onClose;
  const childrenRef = useRef<HTMLDivElement>();
  stack.childrenRef = childrenRef;
  const actionButtonRef = useRef<HTMLDivElement>();
  stack.actionButtonRef = actionButtonRef;
  useEffect(() => {
    if (contextStack) contextStack.setNext(stack);
    return () => {
      if (contextStack) contextStack.setNext(undefined);
    };
  }, [contextStack, stack]);
  return {
    actionButtonRef,
    childrenRef,
    isBase: !contextStack,
    prevStack: contextStack,
    stack,
  };
}

function getTitle(stack, defaultTitle) {
  return (() => {
    let temp = stack;
    while (temp.next) temp = temp.next;
    return temp?.title ?? defaultTitle;
  })();
}

export interface StackStateDialogProps extends MPStandardDialogProps {
  children: ReactElement | Array<ReactElement>;
}

export default function StackStateDialog({
  actionButton,
  title,
  onClose,
  children,
  ...props
}: StackStateDialogProps) {
  const { isBase, prevStack, stack, actionButtonRef, childrenRef } =
    useStackContext(title, onClose);

  const back = useCallback(() => {
    let temp = stack;
    while (temp.next) temp = temp.next;
    temp.onClose?.();
  }, [stack]);

  return (
    <StackContext.Provider value={stack}>
      {isBase ? (
        <MPStandardDialog
          onPrefixClick={stack.next ? back : undefined}
          title={getTitle(stack, title)}
          onClose={onClose}
          actionButton={
            <>
              <div
                className={joinClasses(styles.actionButton, {
                  hidden: !!stack.next,
                })}
              >
                {actionButton}
              </div>
              <div
                className={joinClasses(styles.actionButton, {
                  hidden: !stack.next,
                })}
                ref={actionButtonRef}
              />
            </>
          }
          {...props}
        >
          <div className={stack.next ? 'hidden' : ''}>{children}</div>
          <div className={stack.next ? '' : 'hidden'} ref={childrenRef} />
        </MPStandardDialog>
      ) : (
        <>
          {!!prevStack.actionButtonRef.current &&
            createPortal(
              <>
                <div
                  className={joinClasses(styles.actionButton, {
                    hidden: !!stack.next,
                  })}
                >
                  {actionButton}
                </div>
                <div
                  className={joinClasses(styles.actionButton, {
                    hidden: !stack.next,
                  })}
                  ref={actionButtonRef}
                />
              </>,
              prevStack.actionButtonRef.current
            )}
          {!!prevStack.childrenRef.current &&
            createPortal(
              <>
                <div className={stack.next ? 'hidden' : ''}>{children}</div>
                <div className={stack.next ? '' : 'hidden'} ref={childrenRef} />
              </>,
              prevStack.childrenRef.current
            )}
        </>
      )}
    </StackContext.Provider>
  );
}
