import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';
import { FilterIcon } from '@mp-frontend/core-components/icons';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import FiltersRootContainer from 'components/filters/FiltersRootContainer';
import GTM from 'GTM';
import ArtworkChips from 'pages/explore2/artwork/Chips';
import ArtworkFilterDialog from 'pages/explore2/artwork/FilterDialog';
import FilterSortButton from 'pages/explore2/artwork/FilterSortButton';
import useArtworkFilterState, {
  IsArtworkFilterApplied,
} from 'pages/explore2/artwork/UseArtworkFilterState';
import CSSGlobal from 'types/enums/css/Global';

import ListedForSaleFilter from './ListedForSaleFilter';
import PriceFilter from './PriceFilter';

function Filters() {
  const filterParams = useArtworkFilterState();
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const handleOpenFilterDialog = useCallback(() => {
    setIsFilterDialogOpen(true);
    GTM.explore.trackOpenFilterBox();
  }, []);
  const areFiltersApplied = IsArtworkFilterApplied(filterParams, true);

  return (
    <>
      <FiltersRootContainer>
        <FilterScrollContainer>
          <FiltersSection inline inlineMobile>
            <FiltersGroup>
              <FiltersGroup title="Sorting">
                <FilterSortButton />
              </FiltersGroup>
            </FiltersGroup>

            <FiltersGroup title="Filters">
              <PriceFilter />
              <ListedForSaleFilter />
              <MPActionButton
                startIcon={<FilterIcon />}
                variant="tertiary"
                onClick={handleOpenFilterDialog}
                className={CSSGlobal.Width.maxContent}
                size="small"
              >
                All Filters
              </MPActionButton>
            </FiltersGroup>
          </FiltersSection>
        </FilterScrollContainer>

        {areFiltersApplied ? (
          <FilterScrollContainer>
            <FiltersGroup>
              <ArtworkChips />
            </FiltersGroup>
          </FilterScrollContainer>
        ) : null}
      </FiltersRootContainer>

      {!!isFilterDialogOpen && (
        <ArtworkFilterDialog close={() => setIsFilterDialogOpen(false)} />
      )}
    </>
  );
}

export default Filters;
