.pageTitle {
  margin-bottom: 60px;
}

.wallet {
  padding:32px;
  border: 1px solid var(--color-backgroundDefault);
  width: 100%;
  box-sizing: border-box;
}

.wallet ~ .wallet {
  margin-top: 24px;
}

.defaultConfirmContent {
  padding: 16 34px;
}

.section {
  background-color: var(--color-commonWhite);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionExplanation {
  margin-bottom: 20px;
}

.sectionTitle {
  margin-bottom: 8px;
}

.dialogSubTitle {
  margin-bottom: 8px;
}

.itemStatusRow {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: space-between;
  color: var(--color-commonBlack);
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.formHeader {
  margin-bottom: 6px;
}

.dialogBody {
  padding: 16px 34px;
}

.invalidActionInstructions {
  padding: 24px;
  background-color: var(--color-backgroundDefault);
}

.walletAttributes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

@media (--mq-is-mobile) {
  .section {
    padding: 24px;
  }
  .walletAttributes {
    flex-direction: column;
    gap: 4px;
  }
}
