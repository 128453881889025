import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageSectionsQuery } from 'graphql/__generated__/HomepageSectionsQuery.graphql';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/homepage/HomepageTextBlockSection.module.css';

interface HomepageTextBlockSectionProps {
  textBlock: HomepageSectionsQuery['response']['homepageSections']['edges'][0]['node']['textBlock'];
  hideTopMargin?: boolean;
}

export default function HomepageTextBlockSection({
  hideTopMargin = false,
  textBlock,
}: HomepageTextBlockSectionProps) {
  if (!textBlock?.title && !textBlock?.description) {
    return null;
  }

  return (
    <section
      className={joinClasses(
        CSSGlobal.Cursor.Default,
        CSSGlobal.Flex.CenteredCol
      )}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.CenteredCol,
          CSSGap[10],
          styles.container,
          { [styles.hasTopMargin]: !hideTopMargin }
        )}
      >
        {!!textBlock.title && (
          <div
            className={joinClasses(
              MPFonts.headline4,
              CSSGlobal.TextAlign.Centered
            )}
          >
            {textBlock.title}
          </div>
        )}
        {!!textBlock.description && (
          <div
            className={joinClasses(
              MPFonts.textNormalRegular,
              CSSGlobal.TextAlign.Centered
            )}
          >
            {textBlock.description}
          </div>
        )}
      </div>
    </section>
  );
}
