import { MPActionButton } from '@mp-frontend/core-components';

import CSSGlobal from 'types/enums/css/Global';

interface ToggleFilterProps {
  isSelected: boolean;
  onToggle: () => void;
  title: string;
}

function ToggleFilter({ isSelected, onToggle, title }: ToggleFilterProps) {
  return (
    <MPActionButton
      variant={isSelected ? 'tertiary-inverted' : 'tertiary'}
      className={CSSGlobal.Width.maxContent}
      onClick={onToggle}
      size="small"
    >
      {title}
    </MPActionButton>
  );
}

export default ToggleFilter;
