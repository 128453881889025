import { useCallback, useState } from 'react';

import {
  MPDropdown,
  MPStyledTextField,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useStateBackedObjRef from 'hooks/useStateBackedObjRef';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

export type PriceFilterValue = Partial<{
  max: string;
  min: string;
}>;

interface PriceFilterProps {
  onChange: (value: PriceFilterValue) => void;
  value: PriceFilterValue;
}

export default function PriceFilter({ onChange, value }: PriceFilterProps) {
  const [priceRef, , setPrice] = useStateBackedObjRef(value);
  const [lastValidMinPrice, setLastValidMinPrice] = useState(value.min);
  const [lastValidMaxPrice, setLastValidMaxPrice] = useState(value.max);
  const isMobile = useIsMobile();

  const constrainMaxPrice = useCallback(() => {
    const isPriceLessthanMin =
      priceRef.current.min &&
      parseFloat(priceRef.current.max) < parseFloat(priceRef.current.min);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.max);
    const hasInvalidLeadingZero =
      priceRef.current.max?.length > 1 && priceRef.current.max[0] === '0';

    isPriceLessthanMin || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('max', lastValidMaxPrice)
      : setLastValidMaxPrice(priceRef.current.max);

    onChange(priceRef.current);
  }, [priceRef, lastValidMaxPrice, setPrice, setLastValidMaxPrice, onChange]);

  const constrainMinPrice = useCallback(() => {
    const isPriceGreaterthanMax =
      priceRef.current.max &&
      parseFloat(priceRef.current.min) > parseFloat(priceRef.current.max);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.min);
    const hasInvalidLeadingZero =
      priceRef.current.min?.length > 1 && priceRef.current.min[0] === '0';

    isPriceGreaterthanMax || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('min', lastValidMinPrice)
      : setLastValidMinPrice(priceRef.current.min);

    onChange(priceRef.current);
  }, [priceRef, lastValidMinPrice, setPrice, setLastValidMinPrice, onChange]);

  const handleSetMinPrice = useCallback(
    (minPriceValue: string) => setPrice('min', minPriceValue),
    [setPrice]
  );

  const handleSetMaxPrice = useCallback(
    (maxPriceValue: string) => setPrice('max', maxPriceValue),
    [setPrice]
  );

  return (
    <MPDropdown title="Price Range">
      <div
        className={joinClasses(
          isMobile ? CSSGlobal.Flex.InlineRow : CSSGlobal.Flex.CenteredRow,
          CSSGap[16]
        )}
      >
        <MPStyledTextField
          label="Minimum Price (USD)"
          placeholder="Min Price"
          value={priceRef.current.min ?? ''}
          setValue={handleSetMinPrice}
          onBlur={constrainMinPrice}
          inputMode="numeric"
          startAdornment="$"
        />
        <MPStyledTextField
          label="Maximum Price (USD)"
          placeholder="Max Price"
          value={priceRef.current.max ?? ''}
          setValue={handleSetMaxPrice}
          onBlur={constrainMaxPrice}
          inputMode="numeric"
          startAdornment="$"
        />
      </div>
    </MPDropdown>
  );
}
