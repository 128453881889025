import { useCallback, useEffect, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import ExploreArtworksByArtistPaginatedQueryType, {
  ExploreArtworksByArtistPaginatedQuery,
} from 'graphql/__generated__/ExploreArtworksByArtistPaginatedQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import Carousel from 'components/carousel/Carousel';
import { useExploreQueryParams } from 'components/explore2/ExploreGrid';
import GTM from 'GTM';
import useArtworkFilterState, {
  IsArtworkFilterApplied,
} from 'pages/explore2/artwork/UseArtworkFilterState';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as exploreStyles from 'css/pages/explore2/explore.module.css';
import * as homepageCardStyles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

const CAROUSEL_NAME = 'explore_artworks_by_artist';

interface ArtworksByArtistContentProps {
  artworksByArtistQuery: WithLoadQueryProps<ExploreArtworksByArtistPaginatedQuery>;
  onDataLoad?: (
    results: ExploreArtworksByArtistPaginatedQuery['response']['artwork']['nftsByArtist']['edges'][number]['node'][]
  ) => void;
}

const PreloadedArtworksByArtistContent = withLoadQuery(
  ({ onDataLoad, artworksByArtistQuery }: ArtworksByArtistContentProps) => {
    const {
      artwork: { nftsByArtist },
    } = usePreloadedQuery<ExploreArtworksByArtistPaginatedQuery>(
      ExploreArtworksByArtistPaginatedQueryType,
      artworksByArtistQuery.queryRef
    );

    const nfts = useMemo(
      () => nftsByArtist.edges.map((edge) => edge.node),
      [nftsByArtist.edges]
    );

    useEffect(() => {
      onDataLoad(nfts);
    }, [nfts, onDataLoad]);

    const logViewAbleCardsToGA = useCallback(
      (start: number, end: number) => {
        GTM.ecommerce.trackViewItemList(nfts.slice(start, end), CAROUSEL_NAME);
      },
      [nfts]
    );

    return (
      !!nfts.length && (
        <div className={exploreStyles.artistCarouselContainer}>
          <Carousel
            header={<>Artists</>}
            items={nfts.map((nft, idx) => (
              <ArtworkCard
                key={nft.id}
                nft={nft}
                className={joinClasses(homepageCardStyles.card, {
                  [homepageCardStyles.last]: idx === nfts.length - 1,
                })}
              />
            ))}
            containerName={CAROUSEL_NAME}
            logViewAbleCardsToGA={logViewAbleCardsToGA}
          />
        </div>
      )
    );
  },
  {
    artworksByArtistQuery: {
      concreteRequest: ExploreArtworksByArtistPaginatedQueryType,
    },
  }
);

function ArtworksByArtistContent({
  onDataLoad,
}: Omit<ArtworksByArtistContentProps, 'artworksByArtistQuery'>) {
  const showArtistSearch = useGateValue('gate_artist_search');
  const filterParams = useArtworkFilterState();
  const queryParams = useExploreQueryParams(null, null);
  const areFiltersApplied = IsArtworkFilterApplied(filterParams, true, true);
  const hide = !showArtistSearch || !filterParams.query[0] || areFiltersApplied;

  useEffect(() => {
    if (!hide) return;

    onDataLoad([]);
  }, [hide, onDataLoad]);

  return (
    !hide && (
      <PreloadedArtworksByArtistContent
        onDataLoad={onDataLoad}
        artworksByArtistQuery={{ variables: queryParams }}
      />
    )
  );
}

export default ArtworksByArtistContent;
