/**
 * @generated SignedSource<<5e7e083e3604ec292f16b0267bd22bc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AuctionTypeEnum = "PRODUCT" | "RANKED";
export type BadgeTypeEnum = "NO_BADGE" | "UNLOCKABLE";
export type EthereumTransactionStateEnum = "POSTED" | "MINED" | "CANCELLED" | "PENDING" | "FAILED" | "TRYING";
export type NFTSaleStatusEnum = "APPROVED" | "CANCELLED" | "DISABLED" | "DISPUTED_SALE" | "LIVE" | "NEW" | "PENDING_CANCELLED" | "PENDING_LIVE" | "PENDING_SALE" | "SOLD";
export type ProductTypeBidEnum = "ETHER" | "CREDITCARD";
export type ReserveCurrencyEnum = "ETH" | "USD";
import { FragmentRefs } from "relay-runtime";
export type ExploreArtworksPaginatedFragment$data = {
  readonly artwork: {
    readonly results: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly badgeType: BadgeTypeEnum;
          readonly currentOwner: {
            readonly fullName: string | null;
            readonly id: string;
          } | null;
          readonly contract: {
            readonly isExternal: boolean | null;
          } | null;
          readonly id: string;
          readonly listing: {
            readonly id: string;
            readonly pk: string;
            readonly hasReservePrice: boolean | null;
            readonly reservePriceInEth: number | null;
            readonly reservePriceInUsd: number | null;
            readonly reserveCurrency: ReserveCurrencyEnum;
            readonly productSlug: string;
            readonly fullNftUrl: string | null;
            readonly lowestAskInEth: number | null;
            readonly lowestAskInUsd: number | null;
            readonly disableBidAccepts: boolean;
            readonly availableForBid: boolean | null;
            readonly availableForSale: boolean;
            readonly availableForPurchase: boolean | null;
            readonly custodialSaleEnabled: boolean | null;
            readonly acceptsFiatOffers: boolean | null;
            readonly acceptsFiatPurchase: boolean | null;
            readonly saleCommission: number | null;
            readonly isBidAccepted: boolean | null;
            readonly shouldUseDropSaleContract: boolean | null;
            readonly lastSale: {
              readonly pk: string;
              readonly usdPriceAtSale: number;
              readonly ethPriceAtSale: number;
            } | null;
            readonly productAuction: {
              readonly id?: string;
              readonly info?: string | null;
              readonly endsAt?: any;
              readonly createdAt?: any;
              readonly auctionEnded?: boolean | null;
              readonly auctionAbstractUnionType?: AuctionTypeEnum | null;
            } | null;
            readonly rankedAuction: {
              readonly id?: string;
              readonly startsAt?: any | null;
              readonly endsAt?: any | null;
              readonly createdAt?: any;
              readonly auctionEnded?: boolean | null;
              readonly auctionType?: AuctionTypeEnum | null;
              readonly auctionAbstractUnionType?: AuctionTypeEnum | null;
              readonly count?: number;
              readonly highestBid?: {
                readonly id: string;
                readonly bidInEther: number | null;
                readonly bidInUsd: number | null;
              } | null;
              readonly lastBid?: {
                readonly bidAmount: number | null;
                readonly bidInEther: number | null;
                readonly bidInUsd: number | null;
                readonly bidType: ProductTypeBidEnum | null;
                readonly isCcBid: boolean | null;
                readonly isEtherBid: boolean | null;
              } | null;
            } | null;
            readonly liveSale: {
              readonly contract: {
                readonly abidata: string;
                readonly address: string;
              } | null;
              readonly custodialPresalePriceEth: number | null;
              readonly custodialPresalePriceUsd: number | null;
              readonly custodialPriceUsd: number | null;
              readonly custodialPriceEth: number | null;
              readonly ethTx: {
                readonly id: string;
                readonly pk: string;
                readonly transactionState: EthereumTransactionStateEnum;
                readonly transactionUrl: string | null;
                readonly txid: string | null;
              } | null;
              readonly fixedpricesale: {
                readonly priceEth: number | null;
                readonly priceUsd: number | null;
              } | null;
              readonly id: string;
              readonly listingStatus: NFTSaleStatusEnum;
              readonly pk: string;
            } | null;
            readonly liveBid: {
              readonly id: string;
              readonly pk: string;
              readonly bidAmount: number | null;
              readonly bidInUsd: number | null;
              readonly bidInEther: number | null;
              readonly isCcBid: boolean | null;
              readonly isEtherBid: boolean | null;
              readonly bidderAddress: string | null;
              readonly createdAt: any | null;
              readonly isGlobalBid: boolean | null;
              readonly isGlobalBidIgnored: boolean | null;
              readonly onchainId: string | null;
              readonly isBidConfirmed: boolean | null;
              readonly bidderUser: {
                readonly fullName: string | null;
              } | null;
              readonly contract: {
                readonly symbol: string;
                readonly title: string;
                readonly address: string;
                readonly abidata: string;
                readonly isExternal: boolean | null;
              } | null;
            } | null;
          } | null;
          readonly metadata: {
            readonly artworkImageUrl: string | null;
            readonly artworkVideoUrl: string | null;
            readonly author: {
              readonly fullName: string | null;
              readonly id: string;
              readonly pk: string;
              readonly store: {
                readonly cloudinaryLogoUrl: string | null;
                readonly displayLogoUrl: string | null;
                readonly id: string;
                readonly storeSlug: string | null;
              } | null;
            } | null;
            readonly cardImage: string | null;
            readonly cardVideoUrl: string | null;
            readonly currentAccountLike: boolean | null;
            readonly hasVideo: boolean | null;
            readonly highResImage: string | null;
            readonly id: string;
            readonly mediaMetadata: {
              readonly filesize: number | null;
              readonly height: number | null;
              readonly id: string;
              readonly width: number | null;
            } | null;
            readonly pk: string;
            readonly previewImage: string | null;
            readonly rawfileExtension: string;
            readonly totalSupply: number;
            readonly title: string;
            readonly videoUrl: string | null;
          } | null;
          readonly pk: string;
          readonly printEdition: number;
        } | null;
        readonly cursor: string;
      } | null>;
    } | null;
  } | null;
  readonly " $fragmentType": "ExploreArtworksPaginatedFragment";
};
export type ExploreArtworksPaginatedFragment$key = {
  readonly " $data"?: ExploreArtworksPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExploreArtworksPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "artwork",
  "results"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isExternal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auctionEnded",
  "storageKey": null
},
v8 = {
  "alias": "auctionAbstractUnionType",
  "args": null,
  "kind": "ScalarField",
  "name": "auctionType",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInEther",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInUsd",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidAmount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCcBid",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEtherBid",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abidata",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "artists"
    },
    {
      "kind": "RootArgument",
      "name": "aspectRatios"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "editions"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "genres"
    },
    {
      "kind": "RootArgument",
      "name": "mediaTypes"
    },
    {
      "kind": "RootArgument",
      "name": "price"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "showFollowingArtists"
    },
    {
      "kind": "RootArgument",
      "name": "size"
    },
    {
      "kind": "RootArgument",
      "name": "slug"
    },
    {
      "kind": "RootArgument",
      "name": "sortOrder"
    },
    {
      "kind": "RootArgument",
      "name": "sortType"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    },
    {
      "kind": "RootArgument",
      "name": "traits"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PaginatedExploreArtworksQuery.graphql')
    }
  },
  "name": "ExploreArtworksPaginatedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExploreArtworkQuery",
      "kind": "LinkedField",
      "name": "artwork",
      "plural": false,
      "selections": [
        {
          "alias": "results",
          "args": [
            {
              "kind": "Variable",
              "name": "artists",
              "variableName": "artists"
            },
            {
              "kind": "Variable",
              "name": "aspectRatios",
              "variableName": "aspectRatios"
            },
            {
              "kind": "Variable",
              "name": "editions",
              "variableName": "editions"
            },
            {
              "kind": "Variable",
              "name": "genres",
              "variableName": "genres"
            },
            {
              "kind": "Variable",
              "name": "mediaTypes",
              "variableName": "mediaTypes"
            },
            {
              "kind": "Variable",
              "name": "price",
              "variableName": "price"
            },
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "showFollowingArtists",
              "variableName": "showFollowingArtists"
            },
            {
              "kind": "Variable",
              "name": "size",
              "variableName": "size"
            },
            {
              "kind": "Variable",
              "name": "slug",
              "variableName": "slug"
            },
            {
              "kind": "Variable",
              "name": "sortOrder",
              "variableName": "sortOrder"
            },
            {
              "kind": "Variable",
              "name": "sortType",
              "variableName": "sortType"
            },
            {
              "kind": "Variable",
              "name": "status",
              "variableName": "status"
            },
            {
              "kind": "Variable",
              "name": "traits",
              "variableName": "traits"
            }
          ],
          "concreteType": "NFTConnection",
          "kind": "LinkedField",
          "name": "__PaginatedExploreArtworksQuery__results_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NFTEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTAbstractType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "badgeType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AccountAbstractType",
                      "kind": "LinkedField",
                      "name": "currentOwner",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTEthereumContractType",
                      "kind": "LinkedField",
                      "name": "contract",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTListingAbstractType",
                      "kind": "LinkedField",
                      "name": "listing",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasReservePrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reservePriceInEth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reservePriceInUsd",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reserveCurrency",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productSlug",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullNftUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lowestAskInEth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lowestAskInUsd",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "disableBidAccepts",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "availableForBid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "availableForSale",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "availableForPurchase",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "custodialSaleEnabled",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "acceptsFiatOffers",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "acceptsFiatPurchase",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "saleCommission",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isBidAccepted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "shouldUseDropSaleContract",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTPurchaseHistoryType",
                          "kind": "LinkedField",
                          "name": "lastSale",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "usdPriceAtSale",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "ethPriceAtSale",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": "productAuction",
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "auction",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "info",
                                  "storageKey": null
                                },
                                (v5/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/),
                                (v8/*: any*/)
                              ],
                              "type": "NFTAuctionType",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v9/*: any*/),
                              "type": "NFTRankedAuctionType",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": "rankedAuction",
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "auction",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "startsAt",
                                  "storageKey": null
                                },
                                (v5/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "auctionType",
                                  "storageKey": null
                                },
                                (v8/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "count",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "NFTRankedBidType",
                                  "kind": "LinkedField",
                                  "name": "highestBid",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    (v10/*: any*/),
                                    (v11/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "NFTRankedBidType",
                                  "kind": "LinkedField",
                                  "name": "lastBid",
                                  "plural": false,
                                  "selections": [
                                    (v12/*: any*/),
                                    (v10/*: any*/),
                                    (v11/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "bidType",
                                      "storageKey": null
                                    },
                                    (v13/*: any*/),
                                    (v14/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "NFTRankedAuctionType",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v9/*: any*/),
                              "type": "NFTAuctionType",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTSaleType",
                          "kind": "LinkedField",
                          "name": "liveSale",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "NFTEthereumContractType",
                              "kind": "LinkedField",
                              "name": "contract",
                              "plural": false,
                              "selections": [
                                (v15/*: any*/),
                                (v16/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "custodialPresalePriceEth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "custodialPresalePriceUsd",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "custodialPriceUsd",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "custodialPriceEth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TransactionAbstractType",
                              "kind": "LinkedField",
                              "name": "ethTx",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                (v4/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "transactionState",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "transactionUrl",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "txid",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FixedPriceSaleType",
                              "kind": "LinkedField",
                              "name": "fixedpricesale",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "priceEth",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "priceUsd",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "listingStatus",
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTBidType",
                          "kind": "LinkedField",
                          "name": "liveBid",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v4/*: any*/),
                            (v12/*: any*/),
                            (v11/*: any*/),
                            (v10/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "bidderAddress",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isGlobalBid",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isGlobalBidIgnored",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "onchainId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isBidConfirmed",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AccountAbstractType",
                              "kind": "LinkedField",
                              "name": "bidderUser",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "NFTEthereumContractType",
                              "kind": "LinkedField",
                              "name": "contract",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "symbol",
                                  "storageKey": null
                                },
                                (v17/*: any*/),
                                (v16/*: any*/),
                                (v15/*: any*/),
                                (v3/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NFTMetadataAbstractType",
                      "kind": "LinkedField",
                      "name": "metadata",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "artworkImageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "artworkVideoUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountAbstractType",
                          "kind": "LinkedField",
                          "name": "author",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "StoreTypeV3",
                              "kind": "LinkedField",
                              "name": "store",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "cloudinaryLogoUrl",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "displayLogoUrl",
                                  "storageKey": null
                                },
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "storeSlug",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cardImage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cardVideoUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "currentAccountLike",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasVideo",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "highResImage",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "NFTMediaMetadataType",
                          "kind": "LinkedField",
                          "name": "mediaMetadata",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "filesize",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "height",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "width",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "previewImage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rawfileExtension",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalSupply",
                          "storageKey": null
                        },
                        (v17/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "videoUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "printEdition",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "06267c5d01d618797dd54335573f723a";

export default node;
