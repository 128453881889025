import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { SoldArtworkIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { useTrackingContext } from 'components/trackingContext';
import GTM from 'GTM';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { isNFTDynamic, NFTMedia } from 'utils/nftUtils';

import Embed from './Embed';
import FullScreenPreview from './FullScreenPreview';
import Image from './Image';
import PreviewPagination, {
  usePreviewPaginationState,
} from './PreviewPagination';
import Video from './Video';

import * as styles from 'css/pages/product/ProductPreview.module.css';

import { useGlobalStyle } from 'contexts/GlobalStyleContext';

const DEFAULT_VIDEO_STATE = {
  currentTime: 0,
  isMuted: true,
  isPlaying: true,
};

export interface MediaSize {
  height: number;
  width: number;
}

export interface ProductPreviewProps {
  primaryMedia: NFTMedia;
  additionalMedias?: NFTMedia[];
  className?: string;
  generativeRendererUrl?: string;
  isSoldOut?: boolean;
  mediaClassName?: string;
  showSoldOut?: boolean;
}

export default function ProductPreview({
  primaryMedia,
  additionalMedias = [],
  className,
  mediaClassName,
  isSoldOut = false,
  showSoldOut = false,
  generativeRendererUrl: generativeRendererBaseUrl,
}: ProductPreviewProps) {
  const { source, dropTitle } = useTrackingContext();
  const isMobile = useIsMobile();
  const [medias, setMedias] = useState<NFTMedia[]>([]);
  const [mediasSizes, setMediasSizes] = useState<Record<string, MediaSize>>({});
  const videoRef = useRef<HTMLVideoElement>(undefined);
  const [videoState, setVideoState] = useState<{
    currentTime: number;
    isMuted: boolean;
    isPlaying: boolean;
  }>({ ...DEFAULT_VIDEO_STATE });
  const [showGenerative, setShowGenerative] = useState<boolean>(false);
  const [generativeUniqueId, setGenerativeUniqueId] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isFullScreenLoaded, setIsFullScreenLoaded] = useState<boolean>(false);
  const [isCurrentLoaded, setIsCurrentLoaded] = useState<boolean>(false);
  const generativeRendererUrl = `${generativeRendererBaseUrl}?u=${generativeUniqueId}${
    isMobile ? '&res=150' : '' // GM Studios suggested to use resolution=150 on mobile for performance reasons, otherwise it will render 5k-8k images
  }`;
  const generativeMedia: NFTMedia = {
    hasVideo: false,
    highResUrl: generativeRendererUrl,
    id: generativeRendererUrl,
    lowResUrl: generativeRendererUrl,
    mediumResUrl: generativeRendererUrl,
    rawfileExtension: 'html',
    videoUrl: null,
  };
  const handlePaginationChange = useCallback(() => {
    setIsCurrentLoaded(false);
    setVideoState({ ...DEFAULT_VIDEO_STATE });
  }, []);
  const paginationState = usePreviewPaginationState({
    medias,
    onChange: handlePaginationChange,
  });
  const handleGenerateClick = useCallback(() => {
    setGenerativeUniqueId((prev) => prev + 1);
    setShowGenerative(true);
    GTM.pdp.trackClickGenerateSampleType(source, dropTitle);
  }, [source, dropTitle]);
  const handleSelect = useCallback(
    (index: number) => {
      setShowGenerative(false);
      paginationState.select(index);
    },
    [paginationState]
  );
  const {
    bodyBackgroundColor: {
      reset: resetBodyBackgroundColor,
      update: updateBodyBackgroundColor,
    },
  } = useGlobalStyle();

  const handleFullScreenLoaded = useCallback(
    () => setIsFullScreenLoaded(true),
    []
  );
  const handleCurrentLoaded = useCallback(() => setIsCurrentLoaded(true), []);

  const handleOpenFullScreen = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (isFullScreen) return;

      paginationState.stop();
      setIsFullScreenLoaded(false);
      setIsFullScreen(true);
    },
    [isFullScreen, paginationState]
  );

  const handleVideoTimeUpdate = useCallback(
    (currentTime: number) =>
      setVideoState((prevState) => ({ ...prevState, currentTime })),
    []
  );

  const handleVideoPlayingUpdate = useCallback(
    (isPlaying: boolean) =>
      setVideoState((prevState) => ({ ...prevState, isPlaying })),
    []
  );

  const handleVideoMutedUpdate = useCallback(
    (isMuted: boolean) =>
      setVideoState((prevState) => ({ ...prevState, isMuted })),
    []
  );

  useEffect(() => {
    if (!videoRef.current?.readyState) return;

    setIsCurrentLoaded(true);
  }, [videoRef.current?.readyState, setIsCurrentLoaded]);

  useEffect(() => {
    setMedias([primaryMedia, ...additionalMedias]);
  }, [primaryMedia.id, additionalMedias.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentMedia = useMemo<NFTMedia>(
    () => medias[paginationState.currentIndex],
    [medias, paginationState.currentIndex]
  );

  const currentMediaDimensions = useMemo(
    () => (currentMedia?.id && mediasSizes[currentMedia.id]) || null,
    [currentMedia?.id, mediasSizes]
  );

  useEffect(() => {
    if (!currentMedia || isNFTDynamic(currentMedia.rawfileExtension)) return;

    const img = new window.Image();
    img.src = currentMedia.highResUrl;
    img.onload = () =>
      setMediasSizes(
        (prevState): Record<string, MediaSize> => ({
          ...prevState,
          [currentMedia.id]: {
            height: img.naturalHeight,
            width: img.naturalWidth,
          },
        })
      );
  }, [currentMedia]);

  useEffect(() => {
    isFullScreen
      ? updateBodyBackgroundColor('var(--pdp-image-preview-backdrop-color)')
      : resetBodyBackgroundColor();
  }, [isFullScreen, resetBodyBackgroundColor, updateBodyBackgroundColor]);

  const swipingHandlers = useSwipeable({
    onSwipedLeft: () =>
      showGenerative ? setShowGenerative(false) : paginationState.selectNext(),
    onSwipedRight: () =>
      showGenerative ? setShowGenerative(false) : paginationState.selectPrev(),
    trackMouse: true,
  });

  return currentMedia ? (
    <>
      <FullScreenPreview
        open={isFullScreen}
        onClose={() => setIsFullScreen(false)}
        loaded={isFullScreenLoaded}
        media={currentMedia}
        size={currentMediaDimensions}
        videoProps={{
          ...videoState,
          onLoaded: handleFullScreenLoaded,
          onMutedUpdate: handleVideoMutedUpdate,
          onPlayingUpdate: handleVideoPlayingUpdate,
          onTimeUpdate: handleVideoTimeUpdate,
          ref: (node) => {
            videoRef.current = node;
          },
        }}
      />

      <div className={joinClasses(styles.container, className)}>
        <div
          {...swipingHandlers}
          className={joinClasses(mediaClassName, styles.currentMedia, {
            [styles.zoomable]: !showGenerative,
            [styles.isSoldOut]: showSoldOut && isSoldOut,
            [styles.isLoading]: !isCurrentLoaded,
          })}
        >
          <div
            className={joinClasses(
              CSSGlobal.Flex.RowCenterAlign,
              CSSGap[2],
              MPColorClass.CommonWhite,
              MPBackgroundColorClass.GoldMain,
              styles.soldOutBanner
            )}
          >
            <SoldArtworkIcon />
            <span className={MPFonts.textSmallMedium}>Sold Out</span>
          </div>

          {showGenerative ? (
            <Embed media={generativeMedia} onLoad={handleCurrentLoaded} />
          ) : currentMedia.hasVideo ? (
            !isFullScreen ? (
              <Video
                ref={(node) => {
                  videoRef.current = node;
                }}
                {...videoState}
                src={currentMedia.videoUrl}
                isFullScreen={isFullScreen}
                onClick={handleOpenFullScreen}
                onLoaded={handleCurrentLoaded}
                onTimeUpdate={handleVideoTimeUpdate}
                onPlayingUpdate={handleVideoPlayingUpdate}
                onMutedUpdate={handleVideoMutedUpdate}
              />
            ) : null
          ) : isNFTDynamic(currentMedia.rawfileExtension) ? (
            <Embed
              media={currentMedia}
              onLoad={handleCurrentLoaded}
              onClick={handleOpenFullScreen}
            />
          ) : (
            <Image
              aria-hidden="true"
              media={currentMedia}
              size={currentMediaDimensions}
              isFullScreen={false}
              onClick={handleOpenFullScreen}
              onLoad={handleCurrentLoaded}
            />
          )}
        </div>

        <PreviewPagination
          {...paginationState}
          medias={medias}
          select={handleSelect}
          inactive={showGenerative}
          showGenerateButton={!!generativeRendererBaseUrl}
          onGenerateClick={handleGenerateClick}
        />
      </div>
    </>
  ) : null;
}
