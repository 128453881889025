import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { noop } from 'lodash';
import { useMutation } from 'react-relay';
import { useDisconnect } from 'wagmi';

import {
  MPActionButton,
  MPFonts,
  useMPConfirmDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountChangePrimaryWallet, {
  AccountChangePrimaryWalletMutation,
} from 'graphql/__generated__/AccountChangePrimaryWalletMutation.graphql';
import AccountDeletePayoutWallet, {
  AccountDeletePayoutWalletMutation,
} from 'graphql/__generated__/AccountDeletePayoutWalletMutation.graphql';
import AccountDisconnectWallet, {
  AccountDisconnectWalletMutation,
} from 'graphql/__generated__/AccountDisconnectWalletMutation.graphql';

import { useStackStateConfirmDialog } from 'components/dialogs/StackStateConfirmDialog';
import StackStateDialog from 'components/dialogs/StackStateDialog';
import ErrorDisplay from 'components/Error';
import useSession, { useRefreshSession } from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { areSameAddress } from 'utils/areSameAddress';
import copyToClipboard from 'utils/copyToClipboard';
import promisifyMutation from 'utils/promisifyMutation';

import ApprovedCreatorOBOButton from './ApprovedCreatorOBOButton';
import RenameWalletDialog from './RenameWalletDialog';
import Wallet, { type WalletType } from './Wallet';

import * as styles from 'css/pages/settings/wallet/creditCard/ManageCardDialog.module.css';
import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

import { Wallets } from 'Session';

function useSelectMintingWallet(
  wallet: Wallets[0],
  invalidate,
  cancel,
  setError
): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [selectMintingWalletMutation] =
    useMutation<AccountChangePrimaryWalletMutation>(AccountChangePrimaryWallet);
  const [isTransitioning, startTransition] = useTransition();

  const selectMintingWallet = useCallback(async () => {
    try {
      setIsLoading(true);
      setError();
      await promisifyMutation(selectMintingWalletMutation)({
        address: wallet?.address,
      });
      cancel();
      startTransition(invalidate);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    selectMintingWalletMutation,
    wallet?.address,
    invalidate,
    cancel,
    setError,
  ]);

  return (
    <Fragment key="selectMintingWallet">
      <MPActionButton
        variant="primary"
        fullWidth
        size="large"
        onClick={selectMintingWallet}
        isLoading={isLoading || isTransitioning}
      >
        Select&nbsp;Minting&nbsp;Wallet
      </MPActionButton>
    </Fragment>
  );
}

function useApproveCreator(
  wallet: Wallets[0],
  invalidate,
  setError
): JSX.Element {
  return (
    <Fragment key="useApproveCreator">
      <ApprovedCreatorOBOButton
        wallet={wallet}
        setError={setError}
        invalidate={invalidate}
      />
    </Fragment>
  );
}

function useCopyAddress(wallet: Wallets[0], isPrimary, setError): JSX.Element {
  const [didCopy, setDidCopy] = useState(false);
  const [copyTimeoutId, setCopyTimeoutId] = useState(0);

  const copyAddress = useCallback(() => {
    try {
      window.clearTimeout(copyTimeoutId);
      copyToClipboard(wallet?.address);
      setDidCopy(true);
      setCopyTimeoutId(window.setTimeout(() => setDidCopy(false), 3000));
    } catch (e) {
      setError(e);
    }
  }, [wallet?.address, copyTimeoutId, setError]);

  return (
    <Fragment key="copyAddress">
      <MPActionButton
        variant={isPrimary ? 'primary' : 'secondary'}
        fullWidth
        size="large"
        onClick={copyAddress}
      >
        {didCopy ? 'Address Copied' : 'Copy Address'}
      </MPActionButton>
    </Fragment>
  );
}

function useRenameWallet(wallet: Wallets[0], invalidate): JSX.Element {
  const [
    isRenamedWalletDialogOpen,
    openRenameWalletDialog,
    closeRenameWalletDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  return (
    <Fragment key="renameWallet">
      <MPActionButton
        variant="secondary"
        fullWidth
        size="large"
        onClick={openRenameWalletDialog}
      >
        Rename&nbsp;Wallet
      </MPActionButton>
      {!!isRenamedWalletDialogOpen && (
        <RenameWalletDialog
          isOpen={isRenamedWalletDialogOpen}
          cancel={closeRenameWalletDialog}
          wallet={wallet}
          invalidate={invalidate}
        />
      )}
    </Fragment>
  );
}

function useRemoveWallet(wallet: Wallets[0], invalidate, cancel): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [didTransition, setDidTransition] = useState(false);
  const [error, setError] = useState<Error>();
  const [removeWalletMutation] = useMutation<AccountDisconnectWalletMutation>(
    AccountDisconnectWallet
  );
  const closeDialogRef = useRef(noop);
  const accountAddress = useCachedAccount().address;
  const { disconnect } = useDisconnect();

  const removeWallet = useCallback(async () => {
    const address = wallet?.address;
    try {
      setIsLoading(true);
      closeDialogRef.current();
      await promisifyMutation(removeWalletMutation)({
        address,
      });
      await invalidate();
      if (accountAddress && areSameAddress(address, accountAddress))
        await disconnect();
      setDidTransition(true);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    removeWalletMutation,
    wallet?.address,
    invalidate,
    setError,
    setDidTransition,
    disconnect,
    accountAddress,
  ]);

  useEffect(() => {
    if (didTransition) {
      cancel();
    }
  }, [didTransition, cancel]);

  const [, setDialogOpen, ConfirmDialog] = useStackStateConfirmDialog({
    content: (
      <>
        <div className={MPFonts.textSmallMedium}>
          <ErrorDisplay
            className={CSSGlobal.TextAlign.Centered}
            error={error}
          />
          <div className={styles.removeConfirmationText}>
            You will no longer be able to make Eth purchases using this wallet.
            Are you sure you want to remove this wallet?
          </div>
          <Wallet wallet={wallet} disabled isCollector />
        </div>
      </>
    ),
    onCancel: noop,
    onConfirm: removeWallet,
    title: 'Remove Digital Wallet',
  });

  closeDialogRef.current = () => setDialogOpen(false);

  return (
    <Fragment key="removeWallet">
      <MPActionButton
        key="deleteCard"
        variant="secondary"
        fullWidth
        size="large"
        onClick={() => setDialogOpen(true)}
        isLoading={isLoading}
      >
        Remove&nbsp;Wallet
      </MPActionButton>
      {ConfirmDialog}
    </Fragment>
  );
}

function useDeletePayoutWallet() {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const refreshSession = useRefreshSession();
  const [deletePayoutWalletMutation] =
    useMutation<AccountDeletePayoutWalletMutation>(AccountDeletePayoutWallet);
  const [isTransitioning, startTransition] = useTransition();

  const deletePayoutWallet = useCallback(async () => {
    try {
      setError(undefined);
      setIsLoading(true);
      await promisifyMutation(deletePayoutWalletMutation)({});

      startTransition(refreshSession);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [deletePayoutWalletMutation, refreshSession]);

  const [, setDialogOpen, ConfirmDialog] = useMPConfirmDialog({
    content: (
      <>
        <div
          className={joinClasses(
            MPFonts.textSmallMedium,
            pageStyles.dialogBody
          )}
        >
          <ErrorDisplay
            className={CSSGlobal.TextAlign.Centered}
            error={error}
          />
          <div>Are you sure you want to disconnect your Digital Wallet?</div>
        </div>
      </>
    ),
    onCancel: noop,
    onConfirm: deletePayoutWallet,
    title: 'Disconnect Payout Wallet',
  });

  return (
    <Fragment key="deletePayoutWallet">
      <MPActionButton
        size="large"
        fullWidth
        variant="primary"
        onClick={() => setDialogOpen(true)}
        isLoading={isLoading || isTransitioning}
      >
        Disconnect&nbsp;Digital&nbsp;Wallet
      </MPActionButton>
      {ConfirmDialog}
    </Fragment>
  );
}

interface ManageWalletDialogProps {
  cancel: () => void;
  invalidate: () => void;
  isOpen: boolean;
  wallet: Wallets[0];
}

export default function ManageWalletDialog({
  wallet,
  isOpen,
  invalidate,
  cancel,
  isCreator,
  isActive,
  isCollector,
  isSafebox,
  isPayout,
}: ManageWalletDialogProps & WalletType) {
  const session = useSession();
  const [error, setError] = useState<Error>(undefined);

  const passedWalletType: WalletType = Object.entries({
    isActive,
    isCollector,
    isCreator,
    isPayout,
    isSafebox,
  }).reduce((acc, entry) => {
    if (entry[1]) acc[entry[0]] = true;
    return acc;
  }, {} as WalletType);

  const removeWalletJSX = useRemoveWallet(wallet, invalidate, cancel);
  const renameWalletJSX = useRenameWallet(wallet, invalidate);
  const copyAddressJSX = useCopyAddress(
    wallet,
    !(isCreator && !wallet.isSelectedMintingWallet),
    setError
  );
  const selectMintingWalletJSX = useSelectMintingWallet(
    wallet,
    invalidate,
    cancel,
    setError
  );
  const approveOBOJSX = useApproveCreator(wallet, invalidate, setError);
  const deletePayoutWalletJSX = useDeletePayoutWallet();

  // Typescript seems to struggle with the conditial parameter type only allowing for a single is<> parameter
  const shadowIsPayout: boolean = !!isPayout;

  const buttons = [];
  if (wallet && !shadowIsPayout) {
    if (!wallet.isSafebox && !wallet.isCreatorWallet && !session.isAnon())
      buttons.push(removeWalletJSX);
    if (!wallet.isSafebox && !wallet.isCustodialCreatorWallet)
      buttons.push(renameWalletJSX);
    if (!wallet.isSafebox && !wallet.isCustodialCreatorWallet)
      buttons.push(copyAddressJSX);
    if (!wallet.isSafebox && isCreator) {
      if (!wallet.isCustodialCreatorWallet && !wallet.isSaleContractApproval) {
        buttons.push(approveOBOJSX);
      } else if (!wallet.isSelectedMintingWallet) {
        buttons.push(selectMintingWalletJSX);
      }
    }
  } else if (isPayout) {
    buttons.push(deletePayoutWalletJSX);
  }

  return (
    <StackStateDialog
      title="Manage Digital Wallet"
      open={isOpen}
      onClose={cancel}
      actionButton={buttons}
    >
      <ErrorDisplay error={error} className={CSSGlobal.TextAlign.Centered} />
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          CSSGlobal.Flex.Col,
          CSSGap[16]
        )}
      >
        <span
          className={joinClasses(
            MPFonts.textSmallSemiBold,
            pageStyles.dialogSubTitle
          )}
        >
          Digital Wallet
        </span>
      </div>
      <Wallet {...passedWalletType} wallet={wallet} disabled />
    </StackStateDialog>
  );
}
