import { CSSProperties, ReactNode, ReactText, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, LinkProps } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/cards/BaseCard.module.css';

interface StandardCardProps {
  children: ReactNode;
  onClick: LinkProps['onClick'];
  to: string;
  className?: string;
  disableBrowserNavigate?: boolean;
  disableInAppNavigate?: boolean;
  reloadDocument?: boolean;
  style?: CSSProperties | Record<string, ReactText>;
}

export default function StandardCard({
  children,
  className,
  to,
  disableBrowserNavigate = false,
  disableInAppNavigate = false,
  reloadDocument = false,
  style,
  onClick,
}: StandardCardProps) {
  const showExplore3 = useGateValue('gate_explore_v3');
  const { ref, inView: isInView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });
  const finalClassName = useMemo(
    () =>
      joinClasses(styles.standardCard, 'invisibleAnchor', className, {
        [CSSGlobal.Cursor.Default]: disableBrowserNavigate,
        [CSSGlobal.Cursor.Pointer]: !disableBrowserNavigate,
        [styles.isVisible]: isInView,
        [styles.animated]: showExplore3,
      }),
    [className, disableBrowserNavigate, showExplore3, isInView]
  );

  return disableBrowserNavigate ? (
    <div ref={ref} className={finalClassName} style={style}>
      {children}
    </div>
  ) : disableInAppNavigate ? (
    <a
      ref={ref}
      href={to}
      className={finalClassName}
      style={style}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      ref={ref}
      to={to}
      reloadDocument={reloadDocument}
      className={finalClassName}
      style={style}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
