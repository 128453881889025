import { IBreakpoints } from './breakpoints';
import { FontFamilyCssVariableValue } from './fontFamily';

interface StyleProps {
  'font-family': string;
  'font-size': string;
  'font-weight'?: string;
  'letter-spacing'?: string;
  'line-height'?: string;
  'text-decoration-line'?: string;
  'text-transform'?: string;
}

type BreakpointsToStyle = { [K in keyof IBreakpoints]: StyleProps };
type StyleType = Partial<BreakpointsToStyle> &
  Pick<BreakpointsToStyle, 'mobile'>;

const Typography: Array<[string, StyleType]> = [
  // Title
  [
    'titleMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '56px',
        'letter-spacing': '-3.59px',
        'line-height': '48px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '32px',
        'letter-spacing': '-2px',
        'line-height': '48px',
      },
    },
  ],
  [
    'titleSmall',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '32px',
        'letter-spacing': '-2px',
        'line-height': '48px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '22px',
        'letter-spacing': '-2px',
        'line-height': '48px',
      },
    },
  ],
  [
    'titleXS',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '20px',
        'letter-spacing': '-1px',
        'line-height': '48px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '20px',
        'letter-spacing': '-1px',
        'line-height': '48px',
      },
    },
  ],

  // Body
  [
    'bodyLarge',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '0px',
        'line-height': '31px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '0px',
        'line-height': '31px',
      },
    },
  ],
  [
    'bodyMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
    },
  ],
  [
    'bodySmall',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
    },
  ],

  // Subtitle
  [
    'subtitle1',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '10px',
        'letter-spacing': '0.23px',
        'line-height': '26px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '10px',
        'letter-spacing': '0.23px',
        'line-height': '26px',
      },
    },
  ],
  // Attribute
  [
    'attributeTitle',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
    },
  ],
  [
    'attributeText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
    },
  ],
  [
    'attributeEdition',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '18px',
      },
    },
  ],
  [
    'attributeNumber',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '16px',
        'letter-spacing': '-0.2px',
        'line-height': '20px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '16px',
        'letter-spacing': '-0.2px',
        'line-height': '20px',
      },
    },
  ],
  // Filter
  [
    'filterText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '18px',
        'letter-spacing': '-0.2px',
        'line-height': '38px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '18px',
        'letter-spacing': '-0.2px',
        'line-height': '38px',
      },
    },
  ],
  // Marketplace Insights
  [
    'marketCardOwned',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '12px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '12px',
      },
    },
  ],
  [
    'marketCardArtist',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '20px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '20px',
      },
    },
  ],
  [
    'marketCardInsightsText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '18px',
      },
    },
  ],
  [
    'marketCardInsightsLabel',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '12px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '12px',
        'letter-spacing': '-0.2px',
        'line-height': '12px',
      },
    },
  ],
  // Notifications
  [
    'notificationSettingsLabel',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
    },
  ],
  [
    'notificationBoldText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
    },
  ],
  [
    'notificationRegularText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '21px',
      },
    },
  ],

  // Buttons
  [
    'buttonMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '16px',
      },
    },
  ],
  [
    'buttonSmall',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '1',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '1',
      },
    },
  ],
  [
    'buttonToggle',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '1.42px',
        'line-height': '17px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '12px',
        'letter-spacing': '1.42px',
        'line-height': '17px',
      },
    },
  ],
  // Inputs
  [
    'inputLabel',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.2',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.2',
      },
    },
  ],
  [
    // This should be the same as text small regular, keeping this name, to aid in MUI style overwriting
    'inputText',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0.112px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0.112px',
        'line-height': '1.4',
      },
    },
  ],
  // Others
  [
    'caption',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0.4px',
        'line-height': '16px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'letter-spacing': '0.4px',
        'line-height': '16px',
      },
    },
  ],

  // New Design System
  [
    'headline1',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '91px',
        'letter-spacing': '-5.278px',
        'line-height': '1',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '57px',
        'letter-spacing': '-2.736px',
        'line-height': '1',
      },
    },
  ],
  [
    'headline2',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '68px',
        'letter-spacing': '-3.264px',
        'line-height': '1.02',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '45px',
        'letter-spacing': '-1.44px',
        'line-height': '1.06',
      },
    },
  ],
  [
    'headline3',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '34px',
        'letter-spacing': '-1.088px',
        'line-height': '1.18',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '34px',
        'letter-spacing': '-1.088px',
        'line-height': '1.18',
      },
    },
  ],
  [
    'headline4',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '22px',
        'letter-spacing': '-0.352px',
        'line-height': '1.07',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '22px',
        'letter-spacing': '-0.352px',
        'line-height': '1.07',
      },
    },
  ],
  [
    'paragraphLarge',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '-0.132px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '-0.132px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'paragraphNormal',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '16px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '16px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'paragraphSmall',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'line-height': '1.4',
      },
    },
  ],
  [
    'paragraphXSmall',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '12px',
        'line-height': '1.4',
      },
    },
  ],
  [
    'textLargeMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '22px',
        'letter-spacing': '-0.396px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '22px',
        'letter-spacing': '-0.396px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'textLargeRegular',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '-0.396px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '22px',
        'letter-spacing': '-0.396px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'textNormalMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '16px',
        'letter-spacing': '-0.128px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '16px',
        'letter-spacing': '-0.128px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'textNormalRegular',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '16px',
        'letter-spacing': '-0.128px',
        'line-height': '1.3',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '16px',
        'letter-spacing': '-0.128px',
        'line-height': '1.3',
      },
    },
  ],
  [
    'textSmallBold',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Bold,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.4',
      },
    },
  ],
  [
    'textSmallSemiBold',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.2',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.2',
      },
    },
  ],
  [
    'textSmallMedium',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '14px',
        'letter-spacing': '-0.112px',
        'line-height': '1.4',
      },
    },
  ],
  [
    'textSmallRegular',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0.112px',
        'line-height': '1.4',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Regular,
        'font-size': '14px',
        'letter-spacing': '0.112px',
        'line-height': '1.4',
      },
    },
  ],
  [
    'price',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '18px',
        'letter-spacing': '-0.288px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.SemiBold,
        'font-size': '18px',
        'letter-spacing': '-0.288px',
        'line-height': '18px',
      },
    },
  ],
  [
    'subPrice',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '18px',
        'letter-spacing': '-0.288px',
        'line-height': '18px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '18px',
        'letter-spacing': '-0.288px',
        'line-height': '18px',
      },
    },
  ],
  [
    'homepageHeader',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Light,
        'font-size': '34px',
        'line-height': '44px',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Light,
        'font-size': '22px',
        'line-height': '26px',
      },
    },
  ],
  [
    'productDetailsTitle',
    {
      desktop: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '28px',
        'letter-spacing': '-0.56px',
        'line-height': '1.16',
      },
      mobile: {
        'font-family': FontFamilyCssVariableValue.Medium,
        'font-size': '28px',
        'letter-spacing': '-0.56px',
        'line-height': '1.16',
      },
    },
  ],
];

export default Typography;
