import { useCallback, useState } from 'react';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import ExploreGrid from 'components/explore2/ExploreGrid';
import { TrackingContext } from 'components/trackingContext';
import ArtworksByArtistContent from 'pages/explore2/artwork/ArtworksByArtistContent';
import useArtworkFilterState from 'pages/explore2/artwork/UseArtworkFilterState';
import { NFTType } from 'types/graphql/NFT';

import Filters from './Filters';

import * as styles from 'css/pages/explore2/artwork/ArtworkContent.module.css';

function ArtworkContent() {
  const filterParams = useArtworkFilterState();
  const [isArtistSearchVisible, setIsArtistSearchVisible] = useState(false);
  const handleArtworksByArtistLoad = useCallback(
    (results: NFTType[]) => setIsArtistSearchVisible(!!results.length),
    []
  );

  return (
    <TrackingContext genre={filterParams.genre[0]()}>
      <div className={styles.container}>
        <Filters />
        <ArtworksByArtistContent onDataLoad={handleArtworksByArtistLoad} />
        <DefaultErrorBoundary>
          <ExploreGrid title={isArtistSearchVisible ? 'Artworks' : null} />
        </DefaultErrorBoundary>
      </div>
    </TrackingContext>
  );
}

export default ArtworkContent;
