import {
  MPColorClass,
  MPTooltip,
  MPTooltipProps,
} from '@mp-frontend/core-components';
import { InfoIcon } from '@mp-frontend/core-components/icons';
import { MPSVGIconProps } from '@mp-frontend/core-components/src/icons/MPSVGIcon';

interface InfoTooltipProps {
  title: string;
  fontSize?: MPSVGIconProps['fontSize'];
  placement?: MPTooltipProps['placement'];
}

export default function InfoTooltip({
  title,
  placement = 'bottom',
  fontSize = 'inherit',
}: InfoTooltipProps) {
  return (
    <MPTooltip title={title} placement={placement}>
      <InfoIcon
        className={MPColorClass.SolidNeutralGray3}
        fontSize={fontSize}
      />
    </MPTooltip>
  );
}
