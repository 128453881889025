import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { PreloadedQuery } from 'react-relay';

import {
  MPCheckbox,
  MPDivider,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import { ProductTypeBidEnum } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import { MPErrorName } from 'errors/MPError';
import { useHandleSetETH, useHandleSetUSD } from 'hooks/pricing/useMax';
import useBidProduct from 'hooks/product/bid';
import { useCreditCardPaymentFormState } from 'hooks/product/usePaymentFormState';
import ProductPendingOnChain from 'pages/product/ProductPendingOnChain';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { PurchasableNFTType } from 'types/graphql/NFT';
import IsGlobalContractError from 'utils/errors/contracts/global';
import { getGeneralError } from 'utils/flows/purchaseBids';
import { nftHasRankedAuction } from 'utils/nftUtils';

import PaymentViewBody from './PaymentViewBody';
import PaymentViewDialog from './PaymentViewDialog';
import PaymentViewFooter from './PaymentViewFooter';
import PaymentViewForm from './PaymentViewForm';
import PaymentViewPricing from './PaymentViewPricing';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

export enum ModalType {
  Bid = 'Bid',
  Offer = 'Offer',
}

const getDisclaimerByPaymentMethod = (
  modalType: ModalType
): Record<ProductTypeBidEnum, string> => ({
  [ProductTypeBidEnum.Creditcard]: `Your card will be authorized immediately, but the funds are transferred only after the ${modalType.toLowerCase()} is accepted.`,
  [ProductTypeBidEnum.Ether]: `We will use your digital wallet to submit your ${modalType.toLowerCase()}. You will be asked to confirm your ${modalType.toLowerCase()} amount with your digital wallet.`,
});

function Form({
  handleSubmitRef,
  nft,
  stripeCardsQueryRef,
  modalType,
  onSuccess,
}) {
  const formikProps = useFormikContext<{
    ethBidAmount: string;
    usdBidAmount: string;
  }>();
  const hasRankedAuction = nftHasRankedAuction(nft);

  const [paymentMethod, setPaymentMethod] = useState<ProductTypeBidEnum>(
    ProductTypeBidEnum.Creditcard
  );
  const [formState, updateFormState] = useCreditCardPaymentFormState();
  const [enableGlobalOffer, setEnableGlobalOffer] = useState(false);

  const DISCLAIMER_BY_PAYMENT_METHOD = useMemo(
    () => getDisclaimerByPaymentMethod(modalType),
    [modalType]
  );
  const [bidWithCreditCard, bidWithCreditCardFormState] =
    useBidProduct.useCreditCard({
      ...formState,
      bidAmount: formikProps.values.usdBidAmount,
      bidAmountEth: formikProps.values.ethBidAmount,
      enableGlobalOffer,
      nft,
    });

  const [
    bidWithEthereum,
    bidWithEthereumFormState,
    bidWithEthereumTransaction,
    resetBidWithEthereumTransaction,
  ] = useBidProduct.useEth({
    bidAmount: formikProps.values.ethBidAmount,
    bitAmountUsd: formikProps.values.usdBidAmount,
    enableGlobalOffer,
    nft,
  });

  /* eslint-disable-next-line no-param-reassign */
  handleSubmitRef.current = () =>
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? bidWithCreditCard().then(onSuccess)
      : bidWithEthereum();

  const handleEthereumTransactionSuccess = useCallback(() => {
    if (bidWithEthereumTransaction) {
      resetBidWithEthereumTransaction();
    }
    onSuccess();
  }, [onSuccess, bidWithEthereumTransaction, resetBidWithEthereumTransaction]);

  const isUsingCreditCard = paymentMethod === ProductTypeBidEnum.Creditcard;

  const bidState = isUsingCreditCard
    ? bidWithCreditCardFormState
    : bidWithEthereumFormState;

  const [usdBidAmountFloat, ethBidAmountFloat] = isUsingCreditCard
    ? [
        parseFloat(formikProps.values.usdBidAmount) || 0,
        (parseFloat(formikProps.values.usdBidAmount) || 0) / nft.currentEthRate,
      ]
    : [
        (parseFloat(formikProps.values.ethBidAmount) || 0) * nft.currentEthRate,
        parseFloat(formikProps.values.ethBidAmount) || 0,
      ];

  const inputTypeKey =
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? 'usdBidAmount'
      : 'ethBidAmount';
  // Until formik provides us with an analgoue to setState(prev) we're going to have to go with an memoed version.
  const [, handleUsdBidAmountUpdate] = useHandleSetUSD((value) => {
    formikProps.setFieldValue(
      'usdBidAmount',
      typeof value !== 'function'
        ? value
        : value(formikProps.values.usdBidAmount)
    );
  });
  const [, handleEthBidAmountUpdate] = useHandleSetETH((value) => {
    formikProps.setFieldValue(
      'ethBidAmount',
      typeof value !== 'function'
        ? value
        : value(formikProps.values.ethBidAmount)
    );
  });

  const generalError = getGeneralError(bidState);

  return bidWithEthereumTransaction ? (
    <ProductPendingOnChain
      nft={nft}
      queryVariables={{
        txtId: bidWithEthereumTransaction,
      }}
      onSuccess={handleEthereumTransactionSuccess}
    />
  ) : (
    <>
      <PaymentViewBody>
        {generalError ? (
          <ErrorDisplay
            error={generalError}
            className={CSSGlobal.TextAlign.Centered}
          />
        ) : null}
        <PaymentViewForm
          nft={nft}
          paymentMethod={paymentMethod}
          stripeCardsQueryRef={stripeCardsQueryRef}
          onPaymentMethodChange={setPaymentMethod}
          onFormChange={updateFormState}
          acceptFiat={nft.listing.acceptsFiatPurchase}
        >
          <MPDivider className={styles.paymentFormDivider} />

          <MPStyledTextField
            label={`${modalType} Amount`}
            inputMode="decimal"
            startAdornment={isUsingCreditCard ? '$' : undefined}
            name={inputTypeKey}
            value={formikProps.values[inputTypeKey]}
            error={
              !!formikProps.touched[inputTypeKey] &&
              !bidState.isPending &&
              (bidState.validationError?.name ===
                MPErrorName.BELOW_MINIMUM_BID ||
              bidState.validationError?.name ===
                MPErrorName.MUST_INCREASE_OFFER_OVER_PREVIOUS
                ? bidState.validationError.message
                : IsGlobalContractError.InsufficientFunds(
                    bidState.simulationError
                  )
                ? bidState.simulationError.shortMessage
                : null)
            }
            onBlur={formikProps.handleBlur}
            onChange={
              isUsingCreditCard
                ? handleUsdBidAmountUpdate
                : handleEthBidAmountUpdate
            }
            placeholder={
              (
                isUsingCreditCard
                  ? formikProps.values.usdBidAmount
                  : formikProps.values.ethBidAmount
              )
                ? isUsingCreditCard
                  ? CurrencyDisplayMode.USD
                  : CurrencyDisplayMode.ETH
                : null
            }
          />

          {nft.shouldSupportGlobalBid &&
          !hasRankedAuction &&
          modalType === ModalType.Offer ? (
            <MPCheckbox
              isChecked={enableGlobalOffer}
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => setEnableGlobalOffer(checked)}
              label="Submit Global Offer on All Editions"
              name="should-submit-global-offer"
            />
          ) : null}
        </PaymentViewForm>

        <PaymentViewPricing
          amountInEth={ethBidAmountFloat}
          amountInUsd={usdBidAmountFloat}
          paymentMethod={paymentMethod}
        />

        <div
          className={joinClasses(
            MPFonts.paragraphSmall,
            styles.offerDisclaimer
          )}
        >
          {DISCLAIMER_BY_PAYMENT_METHOD[paymentMethod]}
        </div>
      </PaymentViewBody>

      <PaymentViewFooter
        submitTitle={modalType === ModalType.Offer ? 'Make Offer' : 'Place Bid'}
        onSubmit={formikProps.handleSubmit}
        isDisabled={bidState.isDisabled}
        isLoading={bidState.isPending}
      />
    </>
  );
}

interface PaymentViewOfferProps {
  modalType: ModalType;
  nft: PurchasableNFTType;
  onClose: () => void;
  onSuccess: () => void;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

export default function PaymentViewOffer({
  nft,
  modalType,
  stripeCardsQueryRef,
  onClose,
  onSuccess,
}: PaymentViewOfferProps) {
  const handleSubmitRef = useRef(() => new Promise(undefined));
  return (
    <PaymentViewDialog
      title={modalType === ModalType.Offer ? 'Make an Offer' : 'Place a Bid'}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          ethBidAmount: '',
          usdBidAmount: '',
        }}
        onSubmit={() => handleSubmitRef.current()}
      >
        <Form
          handleSubmitRef={handleSubmitRef}
          nft={nft}
          modalType={modalType}
          stripeCardsQueryRef={stripeCardsQueryRef}
          onSuccess={onSuccess}
        />
      </Formik>
    </PaymentViewDialog>
  );
}
