.dialogTitleOuterContainer {
  --exploreDialog-prefixSize: 32px;
  --exploreDialog-horizontalPadding: 24px;

  box-sizing: border-box;
  padding: 16px var(--exploreDialog-horizontalPadding) 16px 0;
  width: 100%;

  @media (--mq-is-mobile) {
    --exploreDialog-prefixSize: 0px;
  }
}

.dialogTitleContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    background: linear-gradient(var(--exploreDialog-gradientDeg),rgba(245, 245, 245, 0) -2%, var(--color-backgroundDefault) 100%);
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: var(--exploreDialog-horizontalPadding);
    z-index: 1;
  }

  &::before {
    --exploreDialog-gradientDeg: 270deg;

    left: 0;
  }

  &::after {
    --exploreDialog-gradientDeg: 90deg;

    right: 0;
  }
}

.dialogTitleInnerContainer {
  overflow-x: auto;
  width: 100%;
  z-index: -1;
}

.dialogTitle {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 var(--exploreDialog-horizontalPadding) 0 calc(var(--exploreDialog-horizontalPadding) + var(--exploreDialog-prefixSize));
  width: max-content;
}

.dialogContent {
  padding: 0;
  position: relative;
}
