import { useCallback } from 'react';

import { ArtworksFiltersState } from 'pages/explore/artworks/Filters';
import { MoreFiltersDialog } from 'pages/explore/artworks/MoreFilters';

import useArtworkFilterState from './UseArtworkFilterState';

interface ArtworkFilterDialogProps {
  close: () => void;
}

function ArtworkFilterDialog({ close }: ArtworkFilterDialogProps) {
  const filterParams = useArtworkFilterState();

  const handleChange = useCallback(
    (state: Partial<ArtworksFiltersState>) => {
      if (state.aspectRatios) {
        filterParams.dimensions[1](state.aspectRatios);
      }
      if (state.editions) {
        filterParams.editions[1](state.editions);
      }
      if (state.mediaTypes) {
        filterParams.media[1](state.mediaTypes);
      }
      if (state.status) {
        filterParams.status[1](state.status);
      }
      if (state.size) {
        filterParams.minWidthHeight[1]({
          height: state.size.minHeight,
          width: state.size.minWidth,
        });
      }
      close();
    },
    [filterParams, close]
  );

  return (
    <MoreFiltersDialog
      state={{
        aspectRatios: filterParams.dimensions[0],
        editions: filterParams.editions[0],
        mediaTypes: filterParams.media[0],
        size: {
          minHeight: filterParams.minWidthHeight[0].height,
          minWidth: filterParams.minWidthHeight[0].width,
        },
        status: filterParams.status[0],
      }}
      onChange={handleChange}
      onClose={close}
    />
  );
}

export default ArtworkFilterDialog;
