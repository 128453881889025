import { useMemo } from 'react';

import {
  MPActionButton,
  MPColorClass,
  MPDivider,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NewWalletConnectDialog from 'components/wallet/NewWalletConnectDialog';
import { SUPPORT_EMAIL } from 'constants/ExternalUrls';
import { APP_NAME } from 'constants/Utils';
import useWallets from 'hooks/session/useWallets';
import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { areSameAddress } from 'utils/areSameAddress';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';

import Wallet from './Wallet';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';
import * as styles from 'css/pages/settings/wallet/wallets/index.module.css';

function WalletsSection() {
  const session = useSession();
  const account = useCachedAccount();
  const [wallets] = useWallets();

  const [
    isConnectNewCollectionWalletDialogOpen,
    openConnectNewCollectionWalletDialog,
    closeConnectNewCollectionWalletDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  const [
    isConnectNewCreatorWalletDialogOpen,
    openConnectNewCreatorWalletDialog,
    closeConnectNewCreatorWalletDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  const mintingWallets = wallets.filter((wallet) => wallet.isCreatorWallet);
  mintingWallets.sort((a, b) => {
    if (a.isSelectedMintingWallet) return -1;
    if (b.isSelectedMintingWallet) return 1;
    return 0;
  });

  const collectionWallets = useMemo(
    () =>
      wallets.filter(
        (wallet) =>
          !wallet.isCustodialCreatorWallet &&
          !wallet.isSafebox &&
          (!account.address || !areSameAddress(account.address, wallet.address))
      ),
    [account?.address, wallets]
  );

  const safebox = useMemo(
    () => wallets.filter((wallet) => wallet.isSafebox)[0],
    [wallets]
  );

  return (
    <div>
      <div className={joinClasses(MPFonts.headline4, pageStyles.sectionTitle)}>
        Digital Wallets
      </div>
      <div
        className={joinClasses(
          pageStyles.sectionExplanation,
          MPFonts.paragraphSmall
        )}
      >
        You can {session.isCreator() ? 'Mint, ' : ''}Collect
        {session.isCreator() ? ',' : ''} and be paid out to different digital
        wallets.
      </div>
      {session.isCreator() && (
        <div
          className={joinClasses(styles.creatorsSection, pageStyles.section)}
        >
          <div className={MPFonts.textSmallSemiBold}>Minting Wallet</div>
          <div className={MPFonts.paragraphSmall}>
            {`We provide you with a ${APP_NAME} issued contract and minting
            wallet. You can also connect your own personal wallet and mint to a
            ${APP_NAME} issued contract.`}
          </div>
          {mintingWallets.map((wallet) => (
            <Wallet
              wallet={wallet}
              key={wallet.address}
              isCreator
              disabled={
                !!wallet.isSelectedMintingWallet &&
                wallet.isCustodialCreatorWallet
              }
            />
          ))}
          {mintingWallets.findIndex(
            (wallet) =>
              wallet.isCreatorWallet && !wallet.isCustodialCreatorWallet
          ) >= 0 ? (
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                styles.switchCreatorWallet
              )}
            >
              If you need to switch to a new personal wallet for minting you
              will need to contact{' '}
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                className={MPColorClass.CommonBlack}
              >
                {SUPPORT_EMAIL}
              </a>
              .
            </div>
          ) : (
            <MPActionButton
              size="large"
              fullWidth
              variant="primary"
              onClick={openConnectNewCreatorWalletDialog}
            >
              Connect New Wallet
            </MPActionButton>
          )}
          {!!isConnectNewCreatorWalletDialogOpen && (
            <NewWalletConnectDialog
              title="Connect Wallet"
              description="Choose a wallet to connect. You will need to connect and verify this wallet in order to continue."
              isCreationEnabled
              onError={() => {}}
              open={isConnectNewCreatorWalletDialogOpen}
              onClose={closeConnectNewCreatorWalletDialog}
            />
          )}
        </div>
      )}
      <div className={pageStyles.section}>
        <div className={MPFonts.textSmallSemiBold}>
          {`${APP_NAME} Safebox Collection Wallet`}
        </div>
        <div className={MPFonts.textSmallRegular}>
          {`Safebox is a ${APP_NAME} custodial wallet that holds your purchased
          artworks with USD.`}
        </div>
        <Wallet wallet={safebox} isSafebox />
        <MPDivider />
        <div className={MPFonts.textSmallSemiBold}>
          Personal Collection Wallet
        </div>
        <div className={MPFonts.textSmallRegular}>
          Your connected wallet will be used to make offers, bids and store
          artworks purchased in ETH.
        </div>
        {!!account.address && (
          <Wallet
            wallet={wallets.find((wallet) =>
              areSameAddress(wallet.address, account.address)
            )}
            isActive
          />
        )}
        {!session.isAnon() && (
          <MPActionButton
            size="large"
            fullWidth
            variant={!account.address ? 'primary' : 'secondary'}
            onClick={openConnectNewCollectionWalletDialog}
          >
            Connect New Wallet
          </MPActionButton>
        )}
        {!!isConnectNewCollectionWalletDialogOpen && (
          <NewWalletConnectDialog
            title="Connect Wallet"
            description="Choose a wallet to connect. You will need to connect and verify this wallet in order to continue."
            isCreationEnabled={false}
            onError={() => {}}
            open={isConnectNewCollectionWalletDialogOpen}
            onClose={closeConnectNewCollectionWalletDialog}
          />
        )}
        {!session.isAnon() && !!collectionWallets.length && (
          <>
            <MPDivider />
            <div className={MPFonts.textSmallSemiBold}>
              Disconnected Personal Collection Wallets
            </div>
            <div className={MPFonts.textSmallRegular}>
              These are the other verified wallets associated with this account
              that are currently disconnected. You can rename or completely
              remove them from this account.
            </div>
            {collectionWallets.map((wallet) => (
              <Wallet wallet={wallet} key={wallet.address} isCollector />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default withDefaultErrorBoundary(WalletsSection, {
  suspenseless: true,
});
