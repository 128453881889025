import { useCallback } from 'react';

import {
  getArtworkSortLabel,
  SortArtworksFilter,
  SortArtworksFilterValue,
} from 'components/filters/SortFilter';
import GTM from 'GTM';

import useArtworkFilterState from './UseArtworkFilterState';

export default function FilterSortButton() {
  const filterParams = useArtworkFilterState();

  const [sortType, setSortType] = filterParams.sortType;
  const [sortOrder, setSortOrder] = filterParams.sortOrder;

  const handleChange = useCallback(
    (value: SortArtworksFilterValue) => {
      setSortType(value.type);
      setSortOrder(value.order);
      GTM.explore.trackSortByChange(
        getArtworkSortLabel(value.type, value.order)
      );
    },
    [setSortType, setSortOrder]
  );

  return (
    <SortArtworksFilter
      value={{ order: sortOrder, type: sortType }}
      onChange={handleChange}
    />
  );
}
