import { MouseEvent, useCallback, useMemo } from 'react';
import { useExplore } from 'pages/explore';
import { usePreloadedQuery } from 'react-relay';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses } from '@mp-frontend/core-utils';

import HomepageEditorialTilesQueryType, {
  HomepageEditorialTilesQuery,
} from 'graphql/__generated__/HomepageEditorialTilesQuery.graphql';

import EditorialCard from 'components/cards/EditorialCard';
import Carousel from 'components/carousel/Carousel';
import ROUTES from 'constants/Routes';
import { ExploreType } from 'pages/explore/types';
import useHomepageGTM, { HomepageViewItem } from 'utils/GTM/homepage';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

interface EditorialProps {
  editorialQuery: WithLoadQueryProps<HomepageEditorialTilesQuery>;
}

function Editorial({ editorialQuery: { queryRef } }: EditorialProps) {
  const showExplore3 = useGateValue('gate_explore_v3');
  const explore = useExplore();
  const track = useHomepageGTM();
  const result = usePreloadedQuery<HomepageEditorialTilesQuery>(
    HomepageEditorialTilesQueryType,
    queryRef
  );

  const editorials = useMemo(
    () => result.nftEditorials.edges.map((edge) => edge.node),
    [result]
  );

  const logViewAbleCardsToGA = useCallback(
    (start: number, end: number) => {
      const viewItems: HomepageViewItem[] = [];
      editorials.slice(start, end).forEach((editorial) => {
        viewItems.push({
          currency: null,
          item_brand: '',
          item_category: 'pdp',
          item_category4: 'Editorial',
          item_id: editorial.id,
          item_name: editorial.title,
          price: 0,
          price_in_eth: 0,
          quantity: 0,
        });
      });
      track.viewItem(viewItems, 'editorial');
    },
    [track, editorials]
  );

  const handleViewAllClick = useCallback(
    (event: MouseEvent) => {
      if (!showExplore3) return;

      event.preventDefault();
      event.stopPropagation();
      explore.open({ type: ExploreType.Editorials });
    },
    [explore, showExplore3]
  );

  return editorials.length > 0 ? (
    <Carousel
      header={<>Editorial</>}
      viewAllLink={ROUTES.EDITORIAL()}
      onViewAllClick={handleViewAllClick}
      containerName="Editorial"
      logViewAbleCardsToGA={logViewAbleCardsToGA}
      items={editorials.map((tile, idx, array) => (
        <EditorialCard
          key={tile.id}
          className={joinClasses(styles.card, {
            [styles.last]: idx === array.length - 1,
          })}
          editorial={tile}
        />
      ))}
    />
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    Editorial,
    { editorialQuery: { concreteRequest: HomepageEditorialTilesQueryType } },
    { grouppedLoadingKey: 'homepage:editorial-carousel' }
  ),
  { errorFallback: null, hideState: true }
);
