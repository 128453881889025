import { ReactNode } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/filters/FiltersRootContainer.module.css';

export default function FiltersRootContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div
      className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16], styles.container)}
    >
      {children}
    </div>
  );
}
