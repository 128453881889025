import { BaseError as WagmiBaseError } from 'wagmi';

import { joinClasses } from '@mp-frontend/core-utils';

import { MpErrors } from 'types/__generated__/graphql';

import MPGraphQLError from 'errors/MPGraphQLError';
import IsGlobalContractError from 'utils/errors/contracts/global';
import isWalletError from 'utils/errors/wallet';

import * as styles from 'css/components/Error.module.css';

interface ErrorDisplayProps {
  error: MPGraphQLError | WagmiBaseError | Error;
  className?: string;
}

export default function ErrorDisplay({ error, className }: ErrorDisplayProps) {
  let message =
    (error as WagmiBaseError)?.shortMessage ||
    (error instanceof MPGraphQLError &&
    error.name === MpErrors.StripePaymentOthers
      ? error.additionalData?.message
      : false) ||
    error?.message ||
    error?.toString();
  if (IsGlobalContractError.InsufficientFunds(error)) {
    message =
      'You have insufficient ETH to complete this transaction, please add ETH to your wallet to continue your purchase';
  }
  if (isWalletError.userRejected(error)) message = 'You rejected the request';
  return message ? (
    <div className={joinClasses(styles.error, className)}>{message}</div>
  ) : null;
}
