.body {
  display: flex;
  flex-direction: column;
  padding: 16px 34px 22px;

  @media (--mq-is-mobile) {
    padding: 16px 24px 22px;
  }
}

.footer {
  align-items: center;
  border-top: 0.5px solid var(--color-SolidNeutralGray3);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 34px 28px;

  @media (--mq-is-mobile) {
    padding: 9px 24px 28px;
  }
}

.paymentForm {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (--mq-is-mobile) {
    gap: 13px;
  }
}

.paymentFormDivider {
  margin: 8px 0;
}

.paymentMethodDisabledTooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  & > .paymentMethodDisabledIcon {
    color: var(--color-SolidNeutralGray5);
    font-size: 18px;
  }

  & > .paymentMethodDisabledLabel {
    backdrop-filter: blur(16px);
    background: rgba(20, 20, 20, 0.75);
    border-radius: 100px;
    color: var(--color-commonWhite);
    font-size: 10px;
    left: calc(100% + 8px);
    opacity: 0;
    padding: 8px 13px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity ease-in-out 0.2s;
  }

  &:hover {
    & > .paymentMethodDisabledLabel {
      opacity: 1;
    }
  }
}

.paymentFormCreditCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentFormCreditCardInputWrapper {
  position: relative;
}

.paymentFormCreditCardElementWrapper {
  border-color: var(--color-commonBlack);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: var(--color-commonBlack);
  padding: 11px 16px;

  &:not(.focused):hover {
    border-width: 2px;
    background-color: var(--color-backgroundDefault);
    padding: 10px 16px;
  }

  &.empty {
    border-color: var(--color-SolidNeutralGray3);
    color: var(--color-SolidNeutralGray3);
  }

  &.complete:not(.focused) {
    border-color: var(--color-commonBlack);
  }

  &.focused {
    border-color: var(--color-commonBlack);
    border-width: 2px;
    color: var(--color-commonBlack);
    padding: 10px 16px;
  }

  &.error {
    border-color: var(--color-errorLight);
    border-width: 1px;

    &:not(.focused):hover {
      border-color: var(--color-errorLight);
      border-width: 2px;
      color: var(--color-errorMain);
    }

    &.focused {
      border-color: var(--color-errorMain);
      border-width: 2px;
      color: var(--color-errorMain);
    }
  }
}

.paymentFormSavedCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.paymentFormSavedCardContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 19px;
  justify-content: flex-start;

  & > span {
    display: inline-block;
  }
}

.paymentViewPricing {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 46px;
}

.paymentViewPricingValues {
  display: flex;
  flex-direction: row;
  gap: 6px;

  > .paymentViewPricingSecondary {
    color: var(--color-SolidNeutralGray5)
  }
}

.offerDisclaimer {
  color: var(--color-commonBlack);
  cursor: default;
  margin-top: 16px;
}

.reserved {
  display: block;
  color: var(--color-SolidNeutralGray4);
  cursor: default;
  padding: 0;
}

.successView {
  align-items: center;
  color: var(--color-commonBlack);
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  justify-content: center;

  &.default {
    & .successViewIcon {
      color: var(--color-commonBlack);
    }
  }

  &.premium {
    & .successViewIcon {
      color: var(--color-goldMain);
    }
  }
}

.successView > .successViewContent {
  align-items: center;
  color: var(--color-commonBlack);
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
}

.successView > .successViewContent > .successViewSubTitle {
  color: var(--color-SolidNeutralGray5);
}

.successView > .successViewActions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.walletConnectionTitle {
  cursor: default;
}

.generalError {
  color: var(--color-errorMain);
  padding: 0 0 16px;
  text-align: center;
  margin: 0px;
}
