import { useCallback } from 'react';

import BaseListedForSaleFilter, {
  ListedForSaleFilterValue,
} from 'components/filters/ListedForSaleFilter';
import useArtworkFilterState from 'pages/explore2/artwork/UseArtworkFilterState';

function ListedForSaleFilter() {
  const filterParams = useArtworkFilterState();
  const { status } = filterParams;
  const [selectedFilterStatus, setFilterStatus] = status;

  const handleChange = useCallback(
    (value: ListedForSaleFilterValue) => setFilterStatus(value),
    [setFilterStatus]
  );

  return (
    <BaseListedForSaleFilter
      value={selectedFilterStatus}
      onChange={handleChange}
    />
  );
}

export default ListedForSaleFilter;
