import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useTransition,
} from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useRecoilValue } from 'recoil';
import { Hash } from 'viem';
import { ErrorBoundaryProps } from '@sentry/react';

import { MPActionButton } from '@mp-frontend/core-components';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import { RecoilSaleContractQuery } from 'hooks/useSaleContract';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import { Wallets } from 'Session';

interface SetOBOButtonProps {
  invalidate: () => void;
  setError: Dispatch<SetStateAction<ErrorBoundaryProps>>;
  wallet: Wallets[0];
}

function SetOBOButton({
  approvalRegistryContractQuery,
  wallet,
  setError,
  invalidate,
}: {
  approvalRegistryContractQuery: WithLoadQueryProps<NFTContractQuery>;
} & SetOBOButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isTransitioning, startTransition] = useTransition();
  const [isPolling, setIsPolling] = useState(false);
  const [pollVal, setPollVal] = useState(0);

  // Linter automatically turns this into an arrow function.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      if (wallet.isSaleContractApproval === false) {
        startTransition(invalidate);
        const id = setTimeout(() => setPollVal((prev) => prev + 1), 2000);
        return () => clearTimeout(id);
      }
      setIsPolling(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPolling, pollVal]);

  const { nftContract: approvalRegistryContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      approvalRegistryContractQuery.queryRef
    );
  const saleContract = useRecoilValue(RecoilSaleContractQuery);

  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(approvalRegistryContract.abidata).abi,
    contractAddress: approvalRegistryContract.address as Hash,
  });
  const registryOboApprovalManager = useSetOboApprovalForAll({
    isApproved: true,
    operatorAddress: saleContract.address,
  });

  const approveOBO = useCallback(
    async (ev) => {
      ev.preventDefault();
      try {
        setIsLoading(true);
        setError(undefined);
        await registryOboApprovalManager.mutate.writeAsync();
        setIsPolling(true);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [registryOboApprovalManager, setError]
  );

  return (
    <MPActionButton
      variant="primary"
      fullWidth
      size="large"
      onClick={approveOBO}
      isLoading={isLoading || isTransitioning || isPolling}
    >
      Grant&nbsp;OBO&nbsp;Approval
    </MPActionButton>
  );
}

const ApprovedCreatorOBOButtonWithLoad = withLoadQuery(SetOBOButton, {
  approvalRegistryContractQuery: { concreteRequest: NFTContractQueryType },
});

export default function ApprovedCreatorOBOButton(props: SetOBOButtonProps) {
  return (
    <ApprovedCreatorOBOButtonWithLoad
      {...props}
      approvalRegistryContractQuery={{
        variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
      }}
    />
  );
}
