import { MouseEvent, useCallback, useState } from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { CheckCircleIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import User from 'components/accounts/User';
import LoginRequiredButton from 'components/LoginRequiredButton';
import { TrackingContext } from 'components/trackingContext';
import ROUTES from 'constants/Routes';
import { DropClickEventType } from 'GTM';
import { Timer } from 'pages/drops/components/StatusBar';
import SubscriptionDialog from 'pages/drops/components/SubscriptionDialog';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { HomepageExhibitionType } from 'types/graphql/Drop';
import useDropPageGTM from 'utils/GTM/drop';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import ExhibitionFadingEffect from './ExhibitionFadingEffect';
import ExhibitionThumbnail from './ExhibitionThumbnail';
import StandardCard from './StandardCard';

import * as styles from 'css/components/cards/UpcomingExhibitionCard.module.css';

const MAX_COUNT = 3;

interface UpcomingExhibitionCardProps {
  exhibition: HomepageExhibitionType;
  invalidate: () => void;
  className?: string;
}

function UpcomingExhibitionCard({
  exhibition,
  invalidate,
  className,
}: UpcomingExhibitionCardProps) {
  const [showSubscriptionDialog, setShowSubscriptionDialog] =
    useState<boolean>(false);
  const track = useHomepageGTM();
  const dropTrack = useDropPageGTM();

  const handleOpenSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(true);
    dropTrack.trackClickEventType(DropClickEventType.SubscribeForUpdates);
  }, [dropTrack]);
  const handleCloseSubscriptionDialog = useCallback(
    (subscribed = false) => {
      if (subscribed) invalidate();

      setShowSubscriptionDialog(false);
    },
    [invalidate]
  );
  const trackClick = useCallback(
    (artist: HomepageExhibitionType['creators'][number] = null) =>
      track.clickCard(
        CardType.UpcomingExhibitionCard,
        exhibition.pk,
        exhibition.dropTitle,
        artist ? { artist: artist.username } : {}
      ),
    [track, exhibition.pk, exhibition.dropTitle]
  );
  const handleClick = useCallback(() => trackClick(), [trackClick]);
  const handleArtistClick = useCallback(
    (event: MouseEvent, artist: HomepageExhibitionType['creators'][number]) => {
      event.stopPropagation();
      trackClick(artist);
    },
    [trackClick]
  );

  const totalCount =
    exhibition.totalLimitedEditions +
    exhibition.totalOneOfOnes +
    exhibition.totalOpenEditions +
    exhibition.totalRankedAuctions;
  const renderedCount = Math.max(
    MAX_COUNT,
    exhibition.sortedNftMetadatas.edges.length
  );
  const remainingCount = totalCount - renderedCount;

  return (
    <TrackingContext dropTitle={exhibition.dropTitle}>
      <StandardCard
        to={ROUTES.EXHIBITION(exhibition.slug)}
        className={joinClasses(
          MPBackgroundColorClass.BackgroundPaper,
          MPColorClass.CommonBlack,
          styles.upcomingContainer,
          className
        )}
        disableBrowserNavigate={!exhibition.sortedNftMetadatas.edges.length}
        style={{
          '--standardCard-desktopHeight': 'auto',
          '--standardCard-mobileHeight': 'auto',
          '--standardCard-monitorHeight': 'auto',
        }}
        onClick={handleClick}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.RowSpaceBetween,
            CSSGlobal.Flex.RowCenterAlign,
            CSSGlobal.Flex.NoWrap,
            CSSGap[8],
            styles.upcomingContentContainer
          )}
        >
          <ExhibitionFadingEffect className={styles.upcomingContentUser}>
            {exhibition.creators.map((author) => (
              <User
                key={author.id}
                user={author}
                size="small"
                bottomSection="none"
                onClick={(event) => handleArtistClick(event, author)}
              />
            ))}
          </ExhibitionFadingEffect>

          {!exhibition.subscribePhoneNumber ? (
            <LoginRequiredButton
              variant="tertiary-custom"
              className={styles.upcomingActionButton}
              size="large"
              onClick={handleOpenSubscriptionDialog}
            >
              <Timer endDate={exhibition.dropsAt} skipHandlerIfDateWasInPast />
            </LoginRequiredButton>
          ) : (
            <span
              className={joinClasses(
                CSSGlobal.Flex.InlineRow,
                MPFonts.textSmallSemiBold,
                styles.upcomingNoActionTimer
              )}
            >
              <Timer
                endDate={exhibition.dropsAt}
                skipHandlerIfDateWasInPast
                hideIcon
              />
              <CheckCircleIcon fontSize="22" />
            </span>
          )}
        </div>

        <div
          className={joinClasses(
            styles.upcomingFooterContainer,
            CSSGlobal.Flex.Row,
            CSSGap[6]
          )}
        >
          <div
            className={joinClasses(
              styles.upcomingExhibitionTitle,
              MPFonts.textSmallMedium,
              CSSGlobal.Flex.Col,
              CSSGlobal.Flex.JustifyCenter
            )}
          >
            <div className={CSSGlobal.Ellipsis}>{exhibition.dropTitle}</div>
          </div>
          <div
            className={joinClasses(
              CSSGlobal.Flex.InlineRow,
              CSSGap[4],
              CSSGlobal.Flex.NoWrap,
              styles.upcomingThumbnailContainer
            )}
          >
            {exhibition.sortedNftMetadatas.edges
              .slice(0, MAX_COUNT)
              .map(({ node: nftMetadata }) => (
                <ExhibitionThumbnail
                  key={nftMetadata.id}
                  nftMetadata={nftMetadata}
                  className={styles.upcomingThumbnailMedia}
                  cloudinaryParams={{
                    crop: 'limit',
                    height: 80,
                    quality: 'auto:good',
                    width: 80,
                  }}
                />
              ))}
            {remainingCount > 0 && (
              <div
                className={joinClasses(
                  MPFonts.textSmallBold,
                  CSSGlobal.Flex.CenteredCol,
                  CSSGap[4],
                  styles.upcomingThumbnailMedia,
                  styles.upcomingAssetBorder
                )}
              >
                {`+${remainingCount}`}
              </div>
            )}
          </div>
        </div>
      </StandardCard>

      {!!showSubscriptionDialog && (
        <SubscriptionDialog
          dropId={exhibition.pk}
          dropTitle={exhibition.dropTitle}
          dropsAt={exhibition.dropsAt}
          onClose={handleCloseSubscriptionDialog}
        />
      )}
    </TrackingContext>
  );
}

export default UpcomingExhibitionCard;
