import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  MPActionButton,
  MPAnimations,
  MPBackgroundColorClass,
  MPColorClass,
  MPExpandableContent,
  MPExpandableText,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageSectionsQuery$data } from 'graphql/__generated__/HomepageSectionsQuery.graphql';
import ProfilesQueryType, {
  ProfilesQuery,
} from 'graphql/__generated__/ProfilesQuery.graphql';
import { ArtistSpotlightCtaType } from 'types/__generated__/graphql';

import User from 'components/accounts/User';
import { FollowButton } from 'components/buttons/FollowButton';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import ROUTES from 'constants/Routes';
import THRESHOLDS from 'constants/Thresholds';
import Embed from 'pages/product/productPreview/Embed';
import Image from 'pages/product/productPreview/Image';
import useProfileData from 'pages/profiles/useProfileData';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';
import { NFTType } from 'types/graphql/NFT';
import { CloudinaryParams, withCloudinaryParams } from 'utils/cloudinaryUtils';
import emptyFunc from 'utils/emptyFunc';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getNFTPrimaryMedia, isNFTDynamic } from 'utils/nftUtils';
import toNumericShorthand from 'utils/numericShorthand';

import HeroCard from './HeroCard';

import * as styles from 'css/components/cards/HeroArtistCard.module.css';

const CLOUDINARY_PARAMS: CloudinaryParams = {
  crop: 'limit',
  height: 800,
  quality: 'auto:good',
  width: 1300,
};

const MAX_STATS_FOR_CARD = 3;

interface ArtistProps {
  user: Pick<
    AccountArtistFragment,
    'fullName' | 'id' | 'pk' | 'profileImageUrl' | 'username'
  >;
}

function Artist({ user }: ArtistProps) {
  return (
    <div className={styles.user}>
      <User
        nameColorClassName={joinClasses(
          MPColorClass.CommonWhite,
          MPAnimations.Color.LightToDark
        )}
        size="large"
        bottomSection="none"
        topSection={
          <span
            className={joinClasses(
              MPColorClass.SolidNeutralGray2,
              MPFonts.textSmallMedium,
              styles.userLabel
            )}
          >
            Featured Artist
          </span>
        }
        user={user}
      />
    </div>
  );
}

interface DescriptionProps extends ArtistProps {
  actions?: ReadonlyArray<
    Pick<ArtistSpotlightCtaType, 'actionType' | 'id' | 'title' | 'url'>
  >;
  description?: string;
}

function Description({ actions = [], description, user }: DescriptionProps) {
  const action = useMemo(
    () => actions.filter(({ url }) => !!url).pop(),
    [actions]
  );

  return (
    <div className={styles.userDescription}>
      <MPExpandableText
        content={description}
        lineClamp={3}
        title={user.fullName}
        moreText="Read more"
        contentClassName={MPFonts.paragraphSmall}
      />

      <div className={joinClasses(CSSGlobal.Flex.Row, CSSGap[16])}>
        <FollowButton
          size="small"
          userId={parseInt(user.pk, 10)}
          username={user.username}
          variant="secondary-black"
        />

        {!!action && (
          <MPActionButton
            href={action.url}
            size="small"
            variant="secondary-black"
          >
            {action.title}
          </MPActionButton>
        )}
      </div>
    </div>
  );
}

interface StatsProps {
  artworksCount: number;
  followersCount: number;
  fullName: string;
  totalCollectors: number;
  totalExhibitions: number;
  totalVolumeInUsd: number;
}

function Stats({
  followersCount,
  artworksCount,
  totalVolumeInUsd,
  fullName,
  totalCollectors,
  totalExhibitions,
}: StatsProps) {
  const stats = [
    {
      label: 'Sales',
      metThreshold: totalVolumeInUsd > THRESHOLDS.TOTAL_SALES,
      value: `$${toNumericShorthand(totalVolumeInUsd)}`,
    },
    {
      label: 'Collectors',
      metThreshold: totalCollectors > THRESHOLDS.COLLECTORS,
      value: toNumericShorthand(totalCollectors),
    },
    {
      label: 'Original Artworks',
      metThreshold: artworksCount > THRESHOLDS.ARTWORKS,
      value: toNumericShorthand(artworksCount),
    },
    {
      label: 'Audience',
      metThreshold: followersCount > THRESHOLDS.AUDIENCE,
      value: toNumericShorthand(followersCount),
    },
    {
      label: 'Exhibitions',
      metThreshold: totalExhibitions > THRESHOLDS.EXHIBITIONS,
      value: toNumericShorthand(totalExhibitions),
    },
  ].filter(({ metThreshold }) => !!metThreshold);

  return (
    !!stats.length && (
      <MPExpandableContent
        className={MPColorClass.SolidNeutralGray2}
        content={
          <div
            className={joinClasses(
              CSSGlobal.Flex.Col,
              CSSGap[10],
              styles.userStats,
              MPFonts.textSmallMedium,
              MPColorClass.SolidNeutralGray2
            )}
          >
            {stats.slice(0, MAX_STATS_FOR_CARD).map(({ label, value }) => (
              <div className={CSSGlobal.Flex.RowSpaceBetween} key={label}>
                <span>{label}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        }
        moreContent={
          stats.length > MAX_STATS_FOR_CARD && (
            <div
              className={joinClasses(
                CSSGlobal.Flex.Col,
                CSSGap[24],
                MPFonts.textSmallMedium
              )}
            >
              {stats.map(({ label, value }) => (
                <div key={label} className={CSSGlobal.Flex.RowSpaceBetween}>
                  <div className={MPColorClass.SolidNeutralGray5}>{label}</div>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          )
        }
        moreContentLabel="View more stats"
        title={fullName}
        viewMoreFontClassName={MPFonts.textSmallMedium}
      />
    )
  );
}

interface ArtworkProps {
  nft: {
    listing: Pick<NFTType['listing'], 'productSlug'>;
    metadata: Pick<
      NFTType['metadata'],
      | 'hasVideo'
      | 'highResImage'
      | 'id'
      | 'rawfileExtension'
      | 'standardImage'
      | 'thumbnailImage'
      | 'title'
      | 'videoUrl'
    > & {
      mediaMetadata: Pick<
        NFTType['metadata']['mediaMetadata'],
        'height' | 'width'
      >;
    };
  };
}

function Artwork({ nft }: ArtworkProps) {
  const isMobile = useIsMobile();
  const media = useMemo(
    () => (nft?.metadata ? getNFTPrimaryMedia(nft.metadata, isMobile) : null),
    [nft?.metadata, isMobile]
  );

  return (
    media &&
    nft?.listing && (
      <Link
        to={ROUTES.NFT(nft.listing.productSlug)}
        className={styles.artworkContainer}
      >
        {nft.metadata.hasVideo ? (
          <video autoPlay className={styles.artwork} loop muted playsInline>
            <source
              src={withCloudinaryParams(
                nft.metadata.videoUrl,
                CLOUDINARY_PARAMS
              )}
              type="video/mp4"
            />
          </video>
        ) : isNFTDynamic(nft.metadata.rawfileExtension) ? (
          <Embed className={styles.artwork} media={media} />
        ) : (
          <Image
            className={styles.artwork}
            media={media}
            size={nft.metadata.mediaMetadata}
            isFullScreen={false}
            onClick={emptyFunc}
            onLoad={emptyFunc}
            cloudinaryParams={CLOUDINARY_PARAMS}
          />
        )}
      </Link>
    )
  );
}

function BaseHeroArtistCard({
  actions,
  artworksCount = 0,
  description,
  followersCount = 0,
  nft,
  totalVolumeInUsd = 0,
  user,
  fullName,
  totalCollectors = 0,
  totalExhibitions = 0,
}: ArtistProps & DescriptionProps & StatsProps & ArtworkProps) {
  const track = useHomepageGTM();
  const isMobile = useIsMobile();

  const handleClick = useCallback(
    () => track.clickCard(CardType.HeroArtistCard, user.pk, user.fullName),
    [track, user.pk, user.fullName]
  );

  return (
    <HeroCard
      disableBrowserNavigate
      className={joinClasses(
        CSSGlobal.Cursor.Default,
        MPColorClass.CommonWhite,
        styles.container
      )}
      onClick={handleClick}
    >
      {isMobile ? (
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[32])}>
          <Artist user={user} />
          <Artwork nft={nft} />
          <Description
            actions={actions}
            description={description}
            user={user}
          />
          <Stats
            fullName={fullName}
            artworksCount={artworksCount}
            followersCount={followersCount}
            totalVolumeInUsd={totalVolumeInUsd}
            totalCollectors={totalCollectors}
            totalExhibitions={totalExhibitions}
          />
        </div>
      ) : (
        <>
          <div
            className={joinClasses(
              MPBackgroundColorClass.CommonBlack,
              styles.userRail
            )}
          >
            <div
              className={joinClasses(
                CSSGlobal.Flex.ColCenterAlign,
                styles.userContainer
              )}
            >
              <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
                <Artist user={user} />

                <Description
                  actions={actions}
                  description={description}
                  user={user}
                />
              </div>
            </div>

            <Stats
              artworksCount={artworksCount}
              followersCount={followersCount}
              totalVolumeInUsd={totalVolumeInUsd}
              fullName={user.fullName}
              totalCollectors={totalCollectors}
              totalExhibitions={totalExhibitions}
            />
          </div>

          <Artwork nft={nft} />
        </>
      )}
    </HeroCard>
  );
}

interface HeroArtistCardSectionWithProfileDataProps {
  artist: HomepageSectionsQuery$data['homepageSections']['edges'][number]['node']['artist'];
  profileQuery: WithLoadQueryProps<ProfilesQuery>;
}

function HeroArtistCardSectionWithProfileData({
  artist: { artistDescription, ctas, user, nft },
  profileQuery,
}: HeroArtistCardSectionWithProfileDataProps) {
  const profileData = useProfileData(profileQuery.queryRef);

  return (
    <BaseHeroArtistCard
      actions={ctas as ReadonlyArray<ArtistSpotlightCtaType>}
      artworksCount={profileData?.listingStats?.totalItems}
      description={artistDescription}
      nft={nft}
      followersCount={user.followerCount}
      totalVolumeInUsd={user.totalVolume.totalVolumeInUsd}
      user={user}
      fullName={user.fullName}
      totalCollectors={profileData?.numOfOwners}
      totalExhibitions={profileData?.totalExhibitions}
    />
  );
}

export default function HeroArtistCardSection({
  artist,
}: Pick<HeroArtistCardSectionWithProfileDataProps, 'artist'>) {
  return (
    <DefaultErrorBoundary hideState errorFallback={null}>
      {withLoadQuery(HeroArtistCardSectionWithProfileData, {
        profileQuery: { concreteRequest: ProfilesQueryType },
      })({
        artist,
        profileQuery: {
          variables: { slug: artist.user.username },
        },
      })}
    </DefaultErrorBoundary>
  );
}
