import BasePriceFilter from 'components/filters/PriceFilter';
import useArtworkFilterState from 'pages/explore2/artwork/UseArtworkFilterState';

function PriceFilter() {
  const { price } = useArtworkFilterState();
  const [priceFilterState, setPriceFilterState] = price;

  return (
    <BasePriceFilter value={priceFilterState} onChange={setPriceFilterState} />
  );
}

export default PriceFilter;
