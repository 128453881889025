import { Route } from 'react-router-dom';

import WalletPage from './wallet';

const SettingsRoute = (
  <Route path="settings">
    <Route path="wallets" element={<WalletPage />} />
  </Route>
);

export default SettingsRoute;
