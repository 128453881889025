/**
 * @generated SignedSource<<f91a97cb65b7f22354dc39d5d50f7841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountCreatePayoutWalletMutation$variables = {
  signature: string;
  walletAddress: string;
};
export type AccountCreatePayoutWalletMutation$data = {
  readonly createPayoutWallet: {
    readonly success: boolean | null;
  } | null;
};
export type AccountCreatePayoutWalletMutation = {
  variables: AccountCreatePayoutWalletMutation$variables;
  response: AccountCreatePayoutWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "signature"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletAddress"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "signature",
            "variableName": "signature"
          },
          {
            "kind": "Variable",
            "name": "walletAddress",
            "variableName": "walletAddress"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "CreatePayoutWalletMutation",
    "kind": "LinkedField",
    "name": "createPayoutWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCreatePayoutWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountCreatePayoutWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9c4168dbcd2bb43c9ba5e000484ed49",
    "id": null,
    "metadata": {},
    "name": "AccountCreatePayoutWalletMutation",
    "operationKind": "mutation",
    "text": "mutation AccountCreatePayoutWalletMutation(\n  $signature: String!\n  $walletAddress: HexString!\n) {\n  createPayoutWallet(requestData: {signature: $signature, walletAddress: $walletAddress}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd1a4b7ca23459761f43cb429ebb84b0";

export default node;
