import { useCallback } from 'react';

import { ChipsList } from 'pages/explore/artworks/Chips';
import { ArtworksFiltersState } from 'pages/explore/artworks/Filters';

import useArtworkFilterState from './UseArtworkFilterState';

export default function ArtworkChips() {
  const filterParams = useArtworkFilterState();
  const [filterStatus, setFilterStatus] = filterParams.status;
  const [filterEditions, setFilterEditions] = filterParams.editions;
  const [filterMedia, setFilterMedia] = filterParams.media;
  const [filterPrice, setPrice] = filterParams.price;
  const [filterDimensions, setFilterDimensions] = filterParams.dimensions;
  const [filterMinWidthHeight, setFilterMinWidthHeight] =
    filterParams.minWidthHeight;
  const [query, setQuery] = filterParams.query;

  const handleChange = useCallback(
    (state: Partial<ArtworksFiltersState>) => {
      if (state.status !== undefined) setFilterStatus(state.status);
      if (state.editions !== undefined) setFilterEditions(state.editions);
      if (state.mediaTypes !== undefined) setFilterMedia(state.mediaTypes);
      if (state.price !== undefined) setPrice(state.price);
      if (state.aspectRatios !== undefined)
        setFilterDimensions(state.aspectRatios);
      if (state.size !== undefined)
        setFilterMinWidthHeight({
          height: state.size.minHeight,
          width: state.size.minWidth,
        });
      if (state.query !== undefined) setQuery(state.query);
    },
    [
      setFilterStatus,
      setFilterEditions,
      setFilterMedia,
      setPrice,
      setFilterDimensions,
      setFilterMinWidthHeight,
      setQuery,
    ]
  );

  return (
    <ChipsList
      filters={{
        aspectRatios: filterDimensions,
        editions: filterEditions,
        mediaTypes: filterMedia,
        price: filterPrice,
        query,
        size: {
          minHeight: filterMinWidthHeight?.height,
          minWidth: filterMinWidthHeight?.width,
        },
        status: filterStatus,
      }}
      onChange={handleChange}
    />
  );
}
