import { PaginatedExploreEditorialsQuery } from 'graphql/__generated__/PaginatedExploreEditorialsQuery.graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import {
  SortEditorialsFilter,
  SortEditorialsFilterValue,
} from 'components/filters/SortFilter';

export interface EditorialsFiltersState {
  query: string;
  sort: SortEditorialsFilterValue;
}

export const EDITORIALS_FILTERS_DEFAULT_STATE: EditorialsFiltersState = {
  query: '',
  sort: {},
};

export const useEditorialsFiltersQueryValues = (
  filters: EditorialsFiltersState
): PaginatedExploreEditorialsQuery['variables'] => ({
  first: 9,
  query: filters.query,
  sortOrder: filters.sort?.order,
  sortType: filters.sort?.type,
});

interface EditorialsFiltersProps {
  onChange: (state: Partial<EditorialsFiltersState>) => void;
  state: EditorialsFiltersState;
}

export default function Filters({ state, onChange }: EditorialsFiltersProps) {
  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortEditorialsFilter
              value={state.sort}
              onChange={(sort) => onChange({ sort })}
            />
          </FiltersGroup>
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}
