import { useMemo, useState } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import HomepageSectionsQueryType, {
  HomepageSectionsQuery,
} from 'graphql/__generated__/HomepageSectionsQuery.graphql';
import { HomepageSectionTypeEnum } from 'types/__generated__/graphql';

import HeroArtistCard from 'components/cards/HeroArtistCard';
import HeroArtworkCard from 'components/cards/HeroArtworkCard';
import HeroCollectionCard from 'components/cards/HeroCollectionCard';
import HeroEditorialCard from 'components/cards/HeroEditorialCard';
import HeroExhibitionCard from 'components/cards/HeroExhibitionCard';
import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getViewCount } from 'utils/localStorageUtils';

import LiveExhibitions from './homepageExhibitions/LiveExhibitions';
import UpcomingExhibitions from './homepageExhibitions/UpcomingExhibitions';
import CuratedArtworks from './CuratedArtworks';
import ExploreArtworks from './ExploreArtworks';
import HomepageEditorial from './HomepageEditorial';
import HomepageTextBlockSection from './HomepageTextBlockSection';
import InitialVisitHeader from './InitialVisitHeader';

import * as styles from 'css/pages/homepage/HomepageHeroCardSection.module.css';

interface HomepageSectionsProps {
  sectionsQuery: WithLoadQueryProps<HomepageSectionsQuery>;
}

function HomepageSections({
  sectionsQuery: { queryRef },
}: HomepageSectionsProps) {
  const session = useSession();
  const [hasVisited] = useState<boolean>(getViewCount() > 0);
  const result = usePreloadedQuery<HomepageSectionsQuery>(
    HomepageSectionsQueryType,
    queryRef
  );
  const sections = useMemo(
    () => result.homepageSections.edges.map(({ node: section }) => section),
    [result]
  );
  const showInitialVisitHeader = useMemo(
    () =>
      session.isLoggedIn() &&
      !hasVisited &&
      !sections.some(
        ({ sectionType }) => sectionType === HomepageSectionTypeEnum.TextBlock
      ),
    [hasVisited, sections, session]
  );

  return (
    <>
      {!!showInitialVisitHeader && <InitialVisitHeader />}

      {sections.map((section, idx) =>
        section.sectionType === HomepageSectionTypeEnum.HeroArtist ? (
          <section
            key={`hero-artist-${section.artist.id}`}
            className={styles.container}
          >
            {idx > 0 && (
              <div
                className={joinClasses(
                  MPFonts.headline4,
                  CSSGlobal.Cursor.Default,
                  styles.title
                )}
              >
                Artist Spotlight
              </div>
            )}

            <HeroArtistCard artist={section.artist} />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroArtwork ? (
          <section
            key={`hero-artwork-${section.artwork.id}`}
            className={styles.container}
          >
            <HeroArtworkCard
              nft={section.artwork}
              nftMetadata={section.artwork.metadata}
            />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroCollection ? (
          <section
            key={`hero-collection-${section.collection.id}`}
            className={styles.container}
          >
            <HeroCollectionCard collection={section.collection} />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroEditorial ? (
          <section
            key={`hero-editorial-${section.editorial.id}`}
            className={styles.container}
          >
            <HeroEditorialCard editorial={section.editorial} />
          </section>
        ) : section.sectionType === HomepageSectionTypeEnum.HeroExhibition ? (
          <section
            key={`hero-exhibition-${section.exhibition.id}`}
            className={styles.container}
          >
            <HeroExhibitionCard exhibition={section.exhibition} />
          </section>
        ) : section.sectionType ===
          HomepageSectionTypeEnum.CuratedArtworksCarousel ? (
          <CuratedArtworks
            key="curated-artworks-carousel"
            artworkList={section.artworkList}
            curatedNftsQuery={{
              variables: {
                artworkListId: parseInt(section.artworkList.pk, 10),
              },
            }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.DiscoverArtworksCarousel ? (
          <ExploreArtworks
            key="discover-artworks-carousel"
            featuredNftsQuery={{ variables: {} }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.EditorialsCarousel ? (
          <HomepageEditorial
            key="editorials-carousel"
            editorialQuery={{ variables: {} }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.LiveExhibitionsCarousel ? (
          <LiveExhibitions
            key="live-exhibitions-carousel"
            exhibitionsQuery={{ variables: {} }}
            defaultErrorBoundaryConfig={{
              hideState: true,
              suspenseless: false,
            }}
          />
        ) : section.sectionType ===
          HomepageSectionTypeEnum.UpcomingExhibitionsCarousel ? (
          <UpcomingExhibitions
            key="upcoming-exhibitions-carousel"
            exhibitionsQuery={{ variables: {} }}
            defaultErrorBoundaryConfig={{
              hideState: true,
              suspenseless: false,
            }}
          />
        ) : section.sectionType === HomepageSectionTypeEnum.TextBlock ? (
          <HomepageTextBlockSection
            key={`text-block-${section.textBlock.id}`}
            hideTopMargin={idx !== 0}
            textBlock={section.textBlock}
          />
        ) : null
      )}
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    HomepageSections,
    { sectionsQuery: { concreteRequest: HomepageSectionsQueryType } },
    { grouppedLoadingKey: 'homepage:sections' }
  ),
  { errorFallback: null, suspenseless: true }
);
