import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  atom,
  AtomEffect,
  Resetter,
  SetterOrUpdater,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  SortOrderEnum,
  SortTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import { Genres } from '../genre';

const TRAITS_KEY = 'traits';

export const IsArtworkFilterApplied = (
  params: Record<string, any>,
  skipSorting = false,
  skipQuery = false
): boolean =>
  Object.entries(params).some(([key, state]) => {
    switch (key) {
      case 'genre':
        return state[0]() !== Genres.All;
      case 'sortOrder':
        return (
          !skipSorting &&
          !(state[0] === SortOrderEnum.Desc || state[0] === undefined)
        );
      case 'sortType':
        return (
          !skipSorting &&
          !(
            [SortTypeEnum.Popularity, SortTypeEnum.Recommended].includes(
              state[0]
            ) || state[0] === undefined
          )
        );
      case 'query':
        return !skipQuery && !['', undefined].includes(state?.[0]);
      default:
        return typeof state[0] === 'string'
          ? state[0] !== '' && state[0] !== '0'
          : Object.values(state[0]).some((filter) => filter && filter !== '0');
    }
  });

function singleParamStore<T extends string>(
  key: string,
  defaultValue: T = undefined
): AtomEffect<T> {
  return ({ onSet, setSelf, trigger }) => {
    if (trigger === 'get') {
      const param = new URLSearchParams(window.location.search).get(key);
      setSelf(param ? (param as T) : defaultValue);
    }
    onSet((newValue) => {
      const params = new URLSearchParams(window.location.search);
      params.delete(key);
      if (newValue) params.set(key, newValue);
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
      );
    });
  };
}

function multiBooleanObjectStore<T extends string>(
  key: string
): AtomEffect<Record<T, boolean>> {
  return ({ onSet, setSelf, trigger }) => {
    if (trigger === 'get') {
      setSelf(
        new URLSearchParams(window.location.search)
          .getAll(key)
          .reduce((acc, val) => {
            acc[val] = true;
            return acc;
          }, {} as Record<T, boolean>)
      );
    }
    onSet((newValue) => {
      const params = new URLSearchParams(window.location.search);
      params.delete(key);
      const entries = newValue ? Object.entries(newValue) : [];
      if (entries) {
        entries.forEach((entry) => {
          const [val, isSelected] = entry;
          if (isSelected) params.append(key, val);
        });
      }
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
      );
    });
  };
}

function multiStringObjectStore<T extends string>(
  key: string,
  keys: Array<T>
): AtomEffect<Partial<Record<T, string>>> {
  return ({ onSet, setSelf, trigger }) => {
    if (trigger === 'get') {
      const searchParams = new URLSearchParams(window.location.search);
      const record: Partial<Record<T, string>> = {};
      keys.forEach((val) => {
        const urlVal = searchParams.get(`${key}-${val}`);
        if (urlVal) record[val as T] = urlVal;
      });
      setSelf(record);
    }
    onSet((newValue) => {
      const params = new URLSearchParams(window.location.search);
      keys.forEach((val) => {
        params.delete(`${key}-${val}`);
        if (newValue[val as T]) params.set(`${key}-${val}`, newValue[val as T]);
      });
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
      );
    });
  };
}

function traitsObjectStore<T extends string>(): AtomEffect<
  Partial<Record<T, string>>
> {
  return ({ onSet, setSelf, trigger }) => {
    if (trigger === 'get') {
      const searchParams = new URLSearchParams(window.location.search);
      const record: Partial<Record<T, string>> = {};
      searchParams.forEach((val, localKey) => {
        if (localKey.startsWith(TRAITS_KEY)) {
          const traitKey = localKey.replace(`${TRAITS_KEY}-`, '') as T;
          record[traitKey] = val;
        }
      });
      setSelf(record);
    }
    onSet((traits) => {
      const params = new URLSearchParams(window.location.search);
      Array.from(params.keys()).forEach((key) => {
        if (key.startsWith(TRAITS_KEY)) {
          params.delete(key);
        }
      });
      Object.entries(traits).forEach(([key, val]) => {
        const keyValuePair = `${TRAITS_KEY}-${key}`;
        if (val) params.set(keyValuePair, val as string);
      });
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${params}`
      );
    });
  };
}

const genreAtom = atom<Genres>({
  default: undefined,
  effects: [singleParamStore<Genres>('genre')],
  key: 'artwork-genre',
});

const statusAtom = atom({
  default: {} as any,
  effects: [multiBooleanObjectStore<StatusEnum>('status')],
  key: 'artwork-status',
});

const editionsAtom = atom({
  default: {} as any,
  effects: [multiBooleanObjectStore<EditionTypeEnum>('editions')],
  key: 'artwork-editions',
});

const mediaAtom = atom({
  default: {} as any,
  effects: [multiBooleanObjectStore<MediaTypeEnum>('media')],
  key: 'artwork-media',
});

// Once these are generated by the server do the correct mapping
export enum ArtworkFilterPrice {
  MAX = 'max',
  MIN = 'min',
}

const priceAtom = atom({
  default: {} as any,
  effects: [
    multiStringObjectStore<ArtworkFilterPrice>('price', [
      ArtworkFilterPrice.MIN,
      ArtworkFilterPrice.MAX,
    ]),
  ],
  key: 'artwork-price',
});

const traitAtom = atom<Record<string, string>>({
  default: {},
  effects: [traitsObjectStore()],
  key: TRAITS_KEY,
});

const dimensionsAtom = atom({
  default: {} as any,
  effects: [multiBooleanObjectStore<AspectRatioEnum>('dimensions')],
  key: 'dimensions',
});

// Once these are generated by the server do the correct mapping
export enum ArtworkFilterMinWidthHeight {
  HEIGHT = 'height',
  WIDTH = 'width',
}

const minWidthHeightAtom = atom({
  default: {} as any,
  effects: [
    multiStringObjectStore<ArtworkFilterMinWidthHeight>('minWidthHeight', [
      ArtworkFilterMinWidthHeight.HEIGHT,
      ArtworkFilterMinWidthHeight.WIDTH,
    ]),
  ],
  key: 'artwork-minWidthHeight',
});

const sortOrderAtom = atom<SortOrderEnum>({
  default: undefined,
  effects: [singleParamStore<SortOrderEnum>('sortOrder', undefined)],
  key: 'sort-order',
});

const sortTypeAtom = atom<SortTypeEnum>({
  default: undefined,
  effects: [singleParamStore<SortTypeEnum>('sortType', undefined)],
  key: 'sort-type',
});

const queryAtom = atom<string>({
  default: '',
  effects: [singleParamStore<string>('query', '')],
  key: 'artwork-query',
});

interface HookReturn {
  dimensions: ReturnType<
    typeof useRecoilState<Partial<Record<`${AspectRatioEnum}`, boolean>>>
  >;
  editions: ReturnType<
    typeof useRecoilState<Partial<Record<`${EditionTypeEnum}`, boolean>>>
  >;
  genre: [() => Genres, (g: Genres) => void];
  media: ReturnType<
    typeof useRecoilState<Partial<Record<`${MediaTypeEnum}`, boolean>>>
  >;
  minWidthHeight: ReturnType<
    typeof useRecoilState<{ height?: string; width?: string }>
  >;
  price: ReturnType<typeof useRecoilState<{ max?: string; min?: string }>>;
  query: [string, SetterOrUpdater<string>];
  sortOrder: [SortOrderEnum, (s: SortOrderEnum) => void];
  sortType: [SortTypeEnum, (s: SortTypeEnum) => void];
  status: ReturnType<
    typeof useRecoilState<Partial<Record<`${StatusEnum}`, boolean>>>
  >;
  traits: ReturnType<typeof useRecoilState<Record<string, string>>>;
}

function useArtworkFilterState(): HookReturn {
  const [genreState, setGenreState] = useRecoilState(genreAtom);
  const status = useRecoilState(statusAtom);
  const editions = useRecoilState(editionsAtom);
  const media = useRecoilState(mediaAtom);
  const price = useRecoilState(priceAtom);
  const dimensions = useRecoilState(dimensionsAtom);
  const minWidthHeight = useRecoilState(minWidthHeightAtom);
  const sortOrder = useRecoilState(sortOrderAtom);
  const sortType = useRecoilState(sortTypeAtom);
  const query = useRecoilState(queryAtom);
  const traits = useRecoilState(traitAtom);

  const getGenre = useCallback(
    () => (!genreState ? Genres.All : genreState),
    [genreState]
  );

  const setGenre = useCallback(
    (genre: Genres) => {
      setGenreState(genre === Genres.All ? undefined : genre);
    },
    [setGenreState]
  );

  return {
    dimensions,
    editions,
    genre: [getGenre, setGenre],
    media,
    minWidthHeight,
    price,
    query,
    sortOrder,
    sortType,
    status,
    traits,
  };
}

export function useSetQueryRecoilState() {
  return useSetRecoilState(queryAtom);
}

export function useMapQueryParamsToFilterStateEffect() {
  const location = useLocation();
  const singleParams: Record<string, [SetterOrUpdater<any>, Resetter]> = {
    genre: [useSetRecoilState(genreAtom), useResetRecoilState(genreAtom)],
    query: [useSetRecoilState(queryAtom), useResetRecoilState(queryAtom)],
    sortOrder: [
      useSetRecoilState(sortOrderAtom),
      useResetRecoilState(sortOrderAtom),
    ],
    sortType: [
      useSetRecoilState(sortTypeAtom),
      useResetRecoilState(sortTypeAtom),
    ],
  };

  // Warning, we don't impose filter dialog constraints here
  const booleanObjectParams: Record<
    string,
    [Record<string, string>, SetterOrUpdater<any>, Resetter]
  > = {
    dimensions: [
      AspectRatioEnum,
      useSetRecoilState(dimensionsAtom),
      useResetRecoilState(dimensionsAtom),
    ],
    editions: [
      EditionTypeEnum,
      useSetRecoilState(editionsAtom),
      useResetRecoilState(editionsAtom),
    ],
    media: [
      MediaTypeEnum,
      useSetRecoilState(mediaAtom),
      useResetRecoilState(mediaAtom),
    ],
    status: [
      StatusEnum,
      useSetRecoilState(statusAtom),
      useResetRecoilState(statusAtom),
    ],
  };

  const stringObjectParams: Record<
    string,
    [Record<string, string>, SetterOrUpdater<any>]
  > = {
    minWidthHeight: [
      ArtworkFilterMinWidthHeight,
      useSetRecoilState(minWidthHeightAtom),
    ],
    price: [ArtworkFilterPrice, useSetRecoilState(priceAtom)],
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    Object.entries(singleParams).forEach(([key, [set, reset]]) =>
      queryParams.has(key) ? set(queryParams.get(key)) : reset()
    );
    Object.entries(booleanObjectParams).forEach(
      ([key, [enumRecord, set, reset]]) => {
        if (queryParams.has(key)) {
          const queryValues = queryParams.getAll(key);
          Object.values(enumRecord).forEach((val) => {
            set((prev) => ({ ...prev, [val]: queryValues.includes(val) }));
          });
        } else {
          reset();
        }
      }
    );
    Object.entries(stringObjectParams).forEach(
      ([prefix, [enumRecord, set]]) => {
        Object.values(enumRecord).forEach((key) => {
          set((prev) => ({
            ...prev,
            [key]: queryParams.has(`${prefix}-${key}`)
              ? queryParams.get(`${prefix}-${key}`)
              : undefined,
          }));
        });
      }
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.search]);
}

export function useResetAllArtworkFilterState() {
  const resetStates = [
    useResetRecoilState(genreAtom),
    useResetRecoilState(statusAtom),
    useResetRecoilState(editionsAtom),
    useResetRecoilState(mediaAtom),
    useResetRecoilState(priceAtom),
    useResetRecoilState(dimensionsAtom),
    useResetRecoilState(minWidthHeightAtom),
    useResetRecoilState(sortOrderAtom),
    useResetRecoilState(sortTypeAtom),
    useResetRecoilState(queryAtom),
    useResetRecoilState(traitAtom),
  ];
  return useCallback(() => {
    resetStates.forEach((resetState) => resetState());

    // Code is more readable, but linter can't handle it
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, resetStates);
}

export default useArtworkFilterState;
