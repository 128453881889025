import ExploreEditorialsPaginatedFragmentType, {
  ExploreEditorialsPaginatedFragment$data,
} from 'graphql/__generated__/ExploreEditorialsPaginatedFragment.graphql';
import PaginatedExploreEditorialsQueryType, {
  PaginatedExploreEditorialsQuery,
} from 'graphql/__generated__/PaginatedExploreEditorialsQuery.graphql';

import EditorialCard from 'components/cards/EditorialCard';

import Cards from '../Cards';
import Layout, { BaseLayoutProps } from '../Layout';
import { ExploreType } from '../types';
import Chips from './Chips';
import Filters, {
  EditorialsFiltersState,
  useEditorialsFiltersQueryValues,
} from './Filters';
import Hero from './Hero';

interface ExploreEditorialsProps extends BaseLayoutProps {
  filters: EditorialsFiltersState;
  onFiltersChange: (value: Partial<EditorialsFiltersState>) => void;
  onFiltersReset: () => void;
}

function ExploreEditorials({
  filters,
  onFiltersChange,
  onFiltersReset,
  ...props
}: ExploreEditorialsProps) {
  const queryValues = useEditorialsFiltersQueryValues(filters);

  return (
    <Layout
      {...props}
      hero={<Hero heroEditorialQuery={{ variables: {} }} />}
      cards={
        <Cards<
          PaginatedExploreEditorialsQuery,
          ExploreEditorialsPaginatedFragment$data['editorial']['results']['edges'][number]['node']
        >
          exploreType={ExploreType.Editorials}
          filters={
            <>
              <Filters state={filters} onChange={onFiltersChange} />
              <Chips
                filters={filters}
                onChange={onFiltersChange}
                onReset={onFiltersReset}
              />
            </>
          }
          variables={queryValues}
          fragmentConcreteRequest={ExploreEditorialsPaginatedFragmentType}
          queryConcreteRequest={PaginatedExploreEditorialsQueryType}
          renderElement={(editorial) => (
            <EditorialCard key={editorial.id} editorial={editorial} />
          )}
        />
      }
    />
  );
}

export default ExploreEditorials;
