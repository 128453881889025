/**
 * @generated SignedSource<<6f5758e67157b7ab9dc8ace9a52249e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountRenameWalletMutation$variables = {
  address: string;
  name: string;
};
export type AccountRenameWalletMutation$data = {
  readonly createWalletName: {
    readonly success: boolean | null;
  } | null;
};
export type AccountRenameWalletMutation = {
  variables: AccountRenameWalletMutation$variables;
  response: AccountRenameWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "CreateWalletNameMutation",
    "kind": "LinkedField",
    "name": "createWalletName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRenameWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountRenameWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54f7ece1b670fe37b7629acf09681dcc",
    "id": null,
    "metadata": {},
    "name": "AccountRenameWalletMutation",
    "operationKind": "mutation",
    "text": "mutation AccountRenameWalletMutation(\n  $address: HexString!\n  $name: String!\n) {\n  createWalletName(requestData: {address: $address, name: $name}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "61f17e2e3cd14ae115bb3ed12a52ea56";

export default node;
