import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';

import CreditCard from './creditCard';
import Wallets from './wallets';
import Withdrawals from './withdrawals';

import * as styles from 'css/pages/settings/wallet/index.module.css';

export default function AccountPage() {
  return (
    <PageErrorBoundary>
      <div className={styles.walletPage}>
        <CreditCard stripeCardQuery={{ variables: {} }} />
        <Wallets />
        <Withdrawals />
      </div>
    </PageErrorBoundary>
  );
}
