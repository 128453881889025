export const STATSIG_EVENT = {
  MP: {
    GENERATIVE_BEGIN_CHECKOUT: 'mp::begin_checkout:generative',
    GENERATIVE_PURCHASE_ERROR: 'mp::purchase_error:generative',
    GENERATIVE_PURCHASE_SUCCESS: 'mp::purchase_success:generative',
    OPEN_EDITION_BEGIN_CHECKOUT: 'mp::begin_checkout:open_edition',
    OPEN_EDITION_PURCHASE_ERROR: 'mp::purchase_error:open_edition',
    OPEN_EDITION_PURCHASE_SUCCESS: 'mp::purchase_success:open_edition',
    PRODUCT_BEGIN_CHECKOUT: 'mp::begin_checkout:product',
    PRODUCT_PURCHASE_ERROR: 'mp::purchase_error:product',
    PRODUCT_PURCHASE_SUCCESS: 'mp::purchase_success:product',
  },
  SENTRY: {
    APP_ERROR: 'sentry::app_error',
  },
};

export default { STATSIG_EVENT };
