import { startTransition, useCallback, useState } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import ErrorDisplay from 'components/Error';
import useWallets from 'hooks/session/useWallets';
import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import useRegister from 'hooks/wallet/mutations/useRegister';
import useCachedAccount from 'hooks/wallet/useCachedAccount';

import ActiveWallet from './ActiveWallet';
import CollectionWallet from './CollectionWallet';
import CreatorWallet from './CreatorWallet';
import ManageWalletDialog from './ManageWalletDialog';
import PayoutWallet from './PayoutWallet';
import SafeboxWallet from './SafeboxWallet';

import * as styles from 'css/pages/settings/wallet/wallets/Wallet.module.css';

import { type Wallets } from 'Session';

export type WalletType =
  | {
      isCreator: true;
      isActive?: never;
      isCollector?: never;
      isPayout?: never;
      isSafebox?: never;
    }
  | {
      isCollector: true;
      isActive?: never;
      isCreator?: never;
      isPayout?: never;
      isSafebox?: never;
    }
  | {
      isSafebox: true;
      isActive?: never;
      isCollector?: never;
      isCreator?: never;
      isPayout?: never;
    }
  | {
      isActive: true;
      isCollector?: never;
      isCreator?: never;
      isPayout?: never;
      isSafebox?: never;
    }
  | {
      isPayout: true;
      isActive?: never;
      isCollector?: never;
      isCreator?: never;
      isSafebox?: never;
    };

interface WalletProps {
  wallet: Wallets[0];
  disabled?: boolean;
}

export default function Wallet({
  wallet,
  isCreator,
  isActive,
  isCollector,
  isSafebox,
  isPayout,
  disabled,
}: WalletProps & WalletType) {
  const [error, setError] = useState<Error>();
  const [
    isManagedWalletDialogOpen,
    openManagedWalletDialog,
    closeManagedWalletDialog,
  ] = useSimpleDialogController({ preventDefault: true });

  const [registerWallet] = useRegister();
  const account = useCachedAccount();
  const [, invalidate] = useWallets();
  const session = useSession();

  // The business logic is simple enough to keep all up here, if it becomes complicated, we'll have to move it downwards.
  const handleClick = useCallback(async () => {
    if (disabled || wallet?.isSafebox) return;
    setError(undefined);
    if (!wallet && !(isPayout && session.account.ethPayoutWallet)) {
      if (account?.address) {
        try {
          await registerWallet(account.address, account.connector);
          startTransition(invalidate);
        } catch (e) {
          setError(e);
        }
      }
    } else {
      openManagedWalletDialog();
    }
  }, [
    openManagedWalletDialog,
    disabled,
    registerWallet,
    account,
    invalidate,
    wallet,
    session,
    isPayout,
  ]);
  const ariaOnEnter = useOnEnterKey(handleClick);

  const passedWalletType: WalletType = Object.entries({
    isActive,
    isCollector,
    isCreator,
    isPayout,
    isSafebox,
  }).reduce((acc, entry) => {
    if (entry[1]) acc[entry[0]] = true;
    return acc;
  }, {} as WalletType);

  return (
    <>
      <ErrorDisplay error={error} />
      <div
        className={joinClasses(styles.wallet, MPFonts.textSmallMedium, {
          disabled,
        })}
        onClick={handleClick}
        onKeyPress={ariaOnEnter}
        role="button"
        tabIndex={0}
      >
        {!!isCreator && <CreatorWallet wallet={wallet} />}
        {!!isCollector && <CollectionWallet wallet={wallet} />}
        {!!isSafebox && <SafeboxWallet wallet={wallet} />}
        {!!isActive && <ActiveWallet wallet={wallet} />}
        {!!isPayout && <PayoutWallet wallet={wallet} />}
      </div>
      {!!isManagedWalletDialogOpen && (
        <ManageWalletDialog
          {...passedWalletType}
          wallet={wallet}
          cancel={closeManagedWalletDialog}
          isOpen={isManagedWalletDialogOpen}
          invalidate={invalidate}
        />
      )}
    </>
  );
}
