import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useBalance } from 'wagmi';

import { ETH_TO_USD_EXCHANGE_RATE_URL } from 'constants/ExternalUrls';

import useCachedAccount from './useCachedAccount';

let ethToUsdPromise = null;

async function getEthToUsdExchangeRate(): Promise<number> {
  try {
    if (ethToUsdPromise) return ethToUsdPromise;
    const res = await fetch(ETH_TO_USD_EXCHANGE_RATE_URL);
    const json = await res.json();
    return json.data.rates.USD;
  } catch (e) {
    return 0;
  } finally {
    ethToUsdPromise = null;
  }
}

const exchangeRateBalanceAtom = atom<number>({
  default: 0,
  key: 'wallet-cached-exchange-rate',
});

const ethAtom = atom<string>({
  default: undefined,
  key: 'wallet-cached-balance',
});

export default function useCurrentBalance() {
  const { address } = useCachedAccount();
  const [ethToUsdExchangeRate, setEthToUsdExchangeRate] = useRecoilState(
    exchangeRateBalanceAtom
  );
  const [eth, setEth] = useRecoilState(ethAtom);

  const balance = useBalance({
    address,
  });

  useEffect(() => {
    if (balance?.data?.formatted) {
      setEth(balance?.data?.formatted);
    }
    const updateExchangeRate = async (): Promise<void> => {
      ethToUsdPromise = getEthToUsdExchangeRate();
      setEthToUsdExchangeRate(await ethToUsdPromise);
    };
    if (ethToUsdExchangeRate === 0) updateExchangeRate();
  }, [
    setEth,
    setEthToUsdExchangeRate,
    ethToUsdExchangeRate,
    balance?.data?.formatted,
  ]);

  return {
    eth: eth ?? '0',
    isReady: !!address && ethToUsdExchangeRate > 0 && eth !== undefined,
    usd: (parseFloat(eth) * ethToUsdExchangeRate).toFixed(2),
  };
}
