import { MouseEvent, ReactNode, useCallback } from 'react';
import { useExplore } from 'pages/explore';
import { Link, LinkProps } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { MPAnimations, MPFonts } from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import { ExploreType } from 'pages/explore/types';
import CSSGlobal from 'types/enums/css/Global';
import useNavbarGTM from 'utils/GTM/navbar';

import useCloseAllPanelsContext from './useCloseAllPanelsContext';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';

interface NavLinkProps extends Omit<LinkProps, 'onClick'> {
  to: string;
  fontClassName?: MPFonts;
  onClick?: LinkProps['onClick'];
  trackItem?: string;
}

export function NavLink({
  className,
  children,
  to,
  reloadDocument,
  replace,
  state,
  trackItem,
  onClick,
  fontClassName = MPFonts.textLargeRegular,
  ...props
}: NavLinkProps) {
  const NavLinkClassName = joinClasses(
    'minContentWidth',
    'invisibleAnchor',
    'max100Width',
    MPAnimations.Color.DarkToLight,
    className,
    fontClassName
  );
  const CloseAllPanels = useCloseAllPanelsContext();
  const Track = useNavbarGTM();
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      Track.clickNavigationItem(trackItem ?? to);
      if (onClick) onClick(event);
      CloseAllPanels();
    },
    [CloseAllPanels, onClick, to, trackItem, Track]
  );
  const handleEnterPress = useOnEnterKey(handleClick);

  return !to ? (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={NavLinkClassName}
      onClick={handleClick}
      onKeyPress={handleEnterPress}
    >
      {children}
    </span>
  ) : to.startsWith('http') ? (
    <a href={to} className={NavLinkClassName} onClick={handleClick} {...props}>
      {children}
    </a>
  ) : (
    <Link
      to={to}
      className={NavLinkClassName}
      reloadDocument={reloadDocument}
      replace={replace}
      state={state}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Link>
  );
}

export function ExploreNavLink({
  children,
  exploreType,
  reloadDocument,
  ...props
}: NavLinkProps & {
  exploreType: ExploreType;
}) {
  const showExplore3 = useGateValue('gate_explore_v3');
  const { open } = useExplore();

  return showExplore3 ? (
    <NavLink
      {...props}
      className={CSSGlobal.Cursor.Pointer}
      to={null}
      onClick={() => open({ type: exploreType })}
    >
      {children}
    </NavLink>
  ) : (
    <NavLink reloadDocument={reloadDocument} {...props}>
      {children}
    </NavLink>
  );
}

interface LinkSectionProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

export default function LinkSection({
  title,
  className,
  children,
}: LinkSectionProps) {
  return (
    <div className={joinClasses(panelStyles.linkSection, className)}>
      <span
        className={joinClasses(
          MPFonts.textSmallMedium,
          panelStyles.sectionHeading
        )}
      >
        {title}
      </span>
      {children}
    </div>
  );
}
