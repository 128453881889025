import { useEffect } from 'react';

import { GrouppedLoadingProvider } from 'components/GrouppedLoadingProvider';
import { APP_NAME } from 'constants/Utils';
import setDocTitle from 'utils/setDocTitle';

import HomepageGenres from './HomepageGenres';
import HomepageSections from './HomepageSections';

import * as styles from 'css/pages/homepage/HomePage.module.css';

function HomePage() {
  useEffect(() => setDocTitle(APP_NAME), []);

  return (
    <GrouppedLoadingProvider>
      <div className={styles.homePage}>
        <div className={styles.content}>
          <HomepageSections sectionsQuery={{ variables: {} }} />
        </div>

        <HomepageGenres genresQuery={{ variables: {} }} />
      </div>
    </GrouppedLoadingProvider>
  );
}

export default HomePage;
