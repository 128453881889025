import { MPColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { APP_NAME } from 'constants/Utils';
import useMPConnect from 'hooks/wallet/useMPConnect';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { type WalletId } from 'utils/jwt/walletUtils';
import { get24PxConnectorIcon } from 'utils/wallet/connectorUtils';

import WalletNameDisplay from './WalletNameDisplay';
import {
  BalanceSubcomponent,
  CreatedArtworksSubcomponent,
} from './WalletSubComponents';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

import { type Wallets } from 'Session';

interface WalletProps {
  wallet: Wallets[0];
}

export default function CreatorWallet({ wallet }: WalletProps) {
  const { findConnectorByAddress } = useMPConnect();
  const Icon = get24PxConnectorIcon(
    findConnectorByAddress(wallet?.address)?.id as WalletId
  );
  return (
    <>
      <div className={pageStyles.itemStatusRow}>
        <span
          className={joinClasses(
            CSSGlobal.Flex.InlineRowCenterAlign,
            CSSGap[8],
            CSSGlobal.Ellipsis
          )}
        >
          {Icon || null}
          <span className={CSSGlobal.Ellipsis}>
            {wallet.isCustodialCreatorWallet ? (
              <span
                className={MPColorClass.SolidNeutralGray5}
              >{`${APP_NAME} Minting Wallet`}</span>
            ) : (
              <WalletNameDisplay
                name={wallet?.name}
                address={wallet?.address}
              />
            )}
          </span>
        </span>
        {!wallet.isSaleContractApproval && !wallet.isCustodialCreatorWallet && (
          <span>Needs&nbsp;Approval</span>
        )}
        {!!wallet.isSelectedMintingWallet && <span>Selected</span>}
      </div>
      <div className={pageStyles.walletAttributes}>
        <CreatedArtworksSubcomponent wallet={wallet} />
        <BalanceSubcomponent wallet={wallet} />
      </div>
    </>
  );
}
