import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inputBaseClasses, outlinedInputClasses } from '@mui/material';

import {
  MPColorClass,
  MPColorValue,
  MPFonts,
  MPTextField,
} from '@mp-frontend/core-components';
import { SearchIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import ExploreArtistsPaginatedFragmentType, {
  ExploreArtistsPaginatedFragment$data,
  ExploreArtistsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreArtistsPaginatedFragment.graphql';
import ExploreArtworksPaginatedFragmentType, {
  ExploreArtworksPaginatedFragment$key,
} from 'graphql/__generated__/ExploreArtworksPaginatedFragment.graphql';
import ExploreEditorialsPaginatedFragmentType, {
  ExploreEditorialsPaginatedFragment$data,
  ExploreEditorialsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreEditorialsPaginatedFragment.graphql';
import ExploreExhibitionsPaginatedFragmentType, {
  ExploreExhibitionsPaginatedFragment$data,
  ExploreExhibitionsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreExhibitionsPaginatedFragment.graphql';
import { NFTsProductCardFragment$data } from 'graphql/__generated__/NFTsProductCardFragment.graphql';
import PaginatedExploreArtistsQueryType, {
  PaginatedExploreArtistsQuery,
} from 'graphql/__generated__/PaginatedExploreArtistsQuery.graphql';
import PaginatedExploreArtworksQueryType, {
  PaginatedExploreArtworksQuery,
} from 'graphql/__generated__/PaginatedExploreArtworksQuery.graphql';
import PaginatedExploreEditorialsQueryType, {
  PaginatedExploreEditorialsQuery,
} from 'graphql/__generated__/PaginatedExploreEditorialsQuery.graphql';
import PaginatedExploreExhibitionsQueryType, {
  PaginatedExploreExhibitionsQuery,
} from 'graphql/__generated__/PaginatedExploreExhibitionsQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import EditorialCard from 'components/cards/EditorialCard';
import ExhibitionCard from 'components/cards/ExhibitionCard';
import UserCard from 'components/cards/UserCard';
import { GrouppedLoadingProvider } from 'components/GrouppedLoadingProvider';
import { TrackingContext } from 'components/trackingContext';
import { APP_NAME } from 'constants/Utils';
import { EcommerceSourceType } from 'GTM';
import { ExploreType } from 'pages/explore/types';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import setDocTitle from 'utils/setDocTitle';

import SearchCarousel from './SearchCarousel';

import * as styles from 'css/pages/search/SearchPage.module.css';

const QUERY_KEY = 'q';
const TITLE = 'Search';

function SearchPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [localQuery, setLocalQuery] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const query = useMemo(
    () => new URLSearchParams(location.search).get(QUERY_KEY) ?? '',
    [location.search]
  );

  useEffect(() => {
    setDocTitle([TITLE, APP_NAME].join(' | '));
    setLocalQuery(query);
    inputRef.current?.focus();
  }, [query]);

  const handleSubmit = useCallback(() => {
    const search = new URLSearchParams();
    if (localQuery) search.set(QUERY_KEY, localQuery);

    navigate({ search: search.toString() });
  }, [localQuery, navigate]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <TrackingContext source={EcommerceSourceType.Search}>
      <GrouppedLoadingProvider>
        <div
          className={joinClasses(CSSGlobal.Cursor.Default, styles.searchPage)}
        >
          <div
            className={joinClasses(
              MPFonts.headline2,
              CSSGlobal.Flex.CenteredCol,
              CSSGap[24],
              CSSMargin.BOTTOM[24]
            )}
          >
            <div className={MPFonts.headline2}>{TITLE}</div>

            <MPTextField
              value={localQuery}
              setValue={setLocalQuery}
              startAdornment={
                <SearchIcon className={MPColorClass.SolidNeutralGray3} />
              }
              onKeyDown={handleKeyDown}
              inputRef={inputRef}
              inputClassName={MPFonts.headline4}
              tabIndex={-1}
              sx={{
                [` .${inputBaseClasses.root}.${outlinedInputClasses.root}`]: {
                  borderColor: MPColorValue.SolidNeutralGray3,
                  borderRadius: '30px',
                  borderWidth: '2px',
                  padding: '10px 16px',
                },
                maxWidth: '600px',
                width: '80%',
              }}
            />
          </div>

          <div className={styles.content}>
            <SearchCarousel<
              PaginatedExploreArtworksQuery,
              ExploreArtworksPaginatedFragment$key,
              NFTsProductCardFragment$data
            >
              exploreType={ExploreType.Artworks}
              search={query}
              fragmentConcreteRequest={ExploreArtworksPaginatedFragmentType}
              queryConcreteRequest={PaginatedExploreArtworksQueryType}
              renderElement={(element, className) => (
                <ArtworkCard
                  key={element.id}
                  className={className}
                  nft={element}
                />
              )}
            />

            <SearchCarousel<
              PaginatedExploreArtistsQuery,
              ExploreArtistsPaginatedFragment$key,
              ExploreArtistsPaginatedFragment$data['artist']['results']['edges'][number]['node']
            >
              exploreType={ExploreType.Artists}
              search={query}
              fragmentConcreteRequest={ExploreArtistsPaginatedFragmentType}
              queryConcreteRequest={PaginatedExploreArtistsQueryType}
              renderElement={(element, className) => (
                <UserCard
                  key={element.id}
                  className={className}
                  user={element}
                  biography={element.biography}
                  createdArtworks={element.createdArtworks}
                  store={element.store}
                />
              )}
            />

            <SearchCarousel<
              PaginatedExploreExhibitionsQuery,
              ExploreExhibitionsPaginatedFragment$key,
              ExploreExhibitionsPaginatedFragment$data['exhibition']['results']['edges'][number]['node']
            >
              exploreType={ExploreType.Exhibitions}
              search={query}
              fragmentConcreteRequest={ExploreExhibitionsPaginatedFragmentType}
              queryConcreteRequest={PaginatedExploreExhibitionsQueryType}
              renderElement={(element, className) => (
                <ExhibitionCard
                  key={element.id}
                  className={className}
                  exhibition={element}
                />
              )}
            />

            <SearchCarousel<
              PaginatedExploreEditorialsQuery,
              ExploreEditorialsPaginatedFragment$key,
              ExploreEditorialsPaginatedFragment$data['editorial']['results']['edges'][number]['node']
            >
              exploreType={ExploreType.Editorials}
              search={query}
              fragmentConcreteRequest={ExploreEditorialsPaginatedFragmentType}
              queryConcreteRequest={PaginatedExploreEditorialsQueryType}
              renderElement={(element, className) => (
                <EditorialCard
                  key={element.id}
                  className={className}
                  editorial={element}
                />
              )}
            />
          </div>
        </div>
      </GrouppedLoadingProvider>
    </TrackingContext>
  );
}

export default SearchPage;
