import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { KeyType, KeyTypeData } from 'react-relay/relay-hooks/helpers';
import { usePaginationFragment, usePreloadedQuery } from 'react-relay';
import { ConcreteRequest, OperationType, ReaderFragment } from 'relay-runtime';

import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { DefaultLoader } from 'components/DefaultSuspense';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import useInfiniteQueryScroll, {
  ConnectionField,
} from 'hooks/useInfiniteQueryScroll';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import { ExploreType } from './types';

import * as styles from 'css/pages/explore/BaseCards.module.css';

interface CardsProps<TQuery extends OperationType, TElement> {
  exploreType: ExploreType;
  filters: ReactNode;
  fragmentConcreteRequest: ReaderFragment;
  queryConcreteRequest: ConcreteRequest;
  renderElement: (element: TElement) => ReactNode;
  variables: TQuery['variables'];
}

export interface CardsGridProps<TQuery extends OperationType, TElement>
  extends Pick<
    CardsProps<TQuery, TElement>,
    | 'exploreType'
    | 'renderElement'
    | 'fragmentConcreteRequest'
    | 'queryConcreteRequest'
  > {
  onLoaded: () => void;
  query: WithLoadQueryProps<TQuery>;
  scrollRef: RefObject<HTMLDivElement>;
  visibilityRef: RefObject<HTMLDivElement>;
}

export const EXPLORE_TYPE_GRAPHQL_NAMESPACE: Record<ExploreType, string> = {
  [ExploreType.Artworks]: 'artwork',
  [ExploreType.Artists]: 'artist',
  [ExploreType.Exhibitions]: 'exhibition',
  [ExploreType.Editorials]: 'editorial',
};

function CardsGrid<
  TQuery extends OperationType,
  TQueryFragmentKey extends KeyType,
  TElement
>({
  query: { queryRef },
  exploreType,
  fragmentConcreteRequest,
  queryConcreteRequest,
  scrollRef,
  visibilityRef,
  renderElement,
  onLoaded,
}: CardsGridProps<TQuery, TElement>) {
  const result = useInfiniteQueryScroll({
    getConnectionField: (
      data: KeyTypeData<TQueryFragmentKey>
    ): ConnectionField =>
      data[EXPLORE_TYPE_GRAPHQL_NAMESPACE[exploreType]].results,
    pageSize: queryRef.variables.first,
    paginatedQueryResults: usePaginationFragment<TQuery, TQueryFragmentKey>(
      fragmentConcreteRequest,
      usePreloadedQuery<TQuery>(queryConcreteRequest, queryRef) as any
    ),
    ref: visibilityRef,
    scrollRef,
  });
  useEffect(() => onLoaded(), [onLoaded]);
  const elements = useMemo(() => result.data as TElement[], [result.data]);

  return !result.loading && !elements.length ? (
    <div
      className={joinClasses(
        'gridSpanRow',
        MPFonts.paragraphSmall,
        MPColorClass.SolidNeutralGray5,
        CSSGlobal.Flex.Col,
        CSSGlobal.Flex.AlignItemsCenter,
        CSSMargin.TOP[32],
        CSSMargin.BOTTOM[32]
      )}
    >
      Sorry, no {exploreType.toLowerCase()} match your filter criteria. Please
      try adjusting your filters.
    </div>
  ) : (
    <>
      {elements.map(renderElement)}

      {!!result.loading && <DefaultLoader className="gridSpanRow" />}
    </>
  );
}

export default function BaseCards<TQuery extends OperationType, TElement>({
  exploreType,
  filters,
  variables,
  fragmentConcreteRequest,
  queryConcreteRequest,
  renderElement,
}: CardsProps<TQuery, TElement>) {
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const visibilityRef = useRef<HTMLDivElement>(null);
  const Grid = useMemo(
    () =>
      withLoadQuery(
        CardsGrid,
        { query: { concreteRequest: queryConcreteRequest } },
        { grouppedLoadingKey: `explore:${exploreType.toLowerCase()}` }
      ),
    [queryConcreteRequest, exploreType]
  );

  const handleLoaded = useCallback(() => setLoaded(true), []);

  useEffect(() => {
    if (!initialLoad) return;

    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  return (
    <DefaultErrorBoundary suspenseless={initialLoad}>
      {!!(loaded && filters) && (
        <div className={joinClasses(CSSGlobal.Flex.Col, styles.filters)}>
          {filters}
        </div>
      )}

      <DefaultErrorBoundary
        suspenseless={initialLoad}
        hideState={initialLoad}
        errorFallback={null}
      >
        <div ref={scrollRef} className={joinClasses(CSSGap[16], styles.grid)}>
          <Grid
            query={{ fetchPolicy: 'store-or-network', variables }}
            exploreType={exploreType}
            fragmentConcreteRequest={fragmentConcreteRequest}
            queryConcreteRequest={queryConcreteRequest}
            scrollRef={scrollRef}
            visibilityRef={visibilityRef}
            renderElement={renderElement}
            onLoaded={handleLoaded}
          />

          <div ref={visibilityRef} className="gridSpanRow">
            &nbsp;
          </div>
        </div>
      </DefaultErrorBoundary>
    </DefaultErrorBoundary>
  );
}
