import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { merge } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  dialogClasses,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  MPBackgroundColorClass,
  MPColorValue,
  MPDivider,
  MPIconButton,
} from '@mp-frontend/core-components';
import { RemoveIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import ToggleFilter from 'components/filters/ToggleFilter';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import {
  ARTISTS_FILTERS_DEFAULT_STATE,
  ArtistsFiltersState,
} from './artists/Filters';
import {
  ARTWORKS_FILTERS_DEFAULT_STATE,
  ArtworksFiltersState,
} from './artworks/Filters';
import {
  EDITORIALS_FILTERS_DEFAULT_STATE,
  EditorialsFiltersState,
} from './editorials/Filters';
import {
  EXHIBITIONS_FILTERS_DEFAULT_STATE,
  ExhibitionsFiltersState,
} from './exhibitions/Filters';
import ExploreArtists from './artists';
import ExploreArtworks from './artworks';
import ExploreEditorials from './editorials';
import ExploreExhibitions from './exhibitions';
import { ExploreType } from './types';

import * as styles from 'css/pages/explore/index.module.css';

const getFiltersDefaultState = (type: ExploreType) => {
  switch (type) {
    case ExploreType.Artworks:
      return ARTWORKS_FILTERS_DEFAULT_STATE;
    case ExploreType.Editorials:
      return EDITORIALS_FILTERS_DEFAULT_STATE;
    case ExploreType.Exhibitions:
      return EXHIBITIONS_FILTERS_DEFAULT_STATE;
    case ExploreType.Artists:
      return ARTISTS_FILTERS_DEFAULT_STATE;
    default:
      return null;
  }
};

type FiltersStateType =
  | ArtistsFiltersState
  | ArtworksFiltersState
  | EditorialsFiltersState
  | ExhibitionsFiltersState;
type OpenFiltersInitialType = (
  | {
      type: ExploreType.Artists;
      filters?: Partial<ArtistsFiltersState>;
    }
  | {
      type: ExploreType.Artworks;
      filters?: Partial<ArtworksFiltersState>;
    }
  | {
      type: ExploreType.Editorials;
      filters?: Partial<EditorialsFiltersState>;
    }
  | {
      type: ExploreType.Exhibitions;
      filters?: Partial<ExhibitionsFiltersState>;
    }
) & {
  hideHero?: boolean;
};
type ExploreContextType = {
  close: () => void;
  open: (initial?: OpenFiltersInitialType) => void;
  show: boolean;
};

const ExploreContext = createContext<ExploreContextType>(null);

export const useExplore = () => {
  const context = useContext(ExploreContext);
  if (!context) {
    throw new Error('useExplore must be used within a ExploreProvider');
  }
  return context;
};

export function ExploreProvider({ children }) {
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [hideHero, setHideHero] = useState<boolean>(false);
  const [exploreType, setExploreType] = useState<ExploreType>(null);
  const filtersState = useState<FiltersStateType>(null);

  useEffect(() => setShow(false), [location]);

  const filters = useMemo(
    () => merge({}, getFiltersDefaultState(exploreType), filtersState[0]),
    [exploreType, filtersState]
  );

  const handleFiltersChange = useCallback(
    (value: Partial<FiltersStateType>) =>
      filtersState[1]((prev: FiltersStateType) => ({
        ...(prev as FiltersStateType),
        ...(value as FiltersStateType),
      })),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFiltersReset = useCallback(
    (
      initialType?: ExploreType,
      initialFilters: Partial<FiltersStateType> = {} as FiltersStateType
    ) =>
      filtersState[1](
        merge(
          {},
          getFiltersDefaultState(
            initialType ?? exploreType
          ) as FiltersStateType,
          initialFilters
        )
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exploreType]
  );

  const handleTypeChange = useCallback(
    (value: ExploreType) => {
      setExploreType(value);
      handleFiltersReset(value);
    },
    [handleFiltersReset]
  );

  const handleOpen = useCallback(
    (
      initial: OpenFiltersInitialType = {
        type: ExploreType.Artworks,
      }
    ) => {
      const initialType = initial?.type ?? ExploreType.Artworks;
      setExploreType(initialType);
      handleFiltersReset(initialType, initial?.filters);
      setHideHero(initial?.hideHero || false);
      setShow(true);
    },
    [handleFiltersReset]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const value = useMemo(
    (): ExploreContextType => ({
      close: handleClose,
      open: handleOpen,
      show,
    }),
    [show, handleOpen, handleClose]
  );

  return (
    <ExploreContext.Provider value={value}>
      {children}

      {!!show && (
        <Dialog
          fullScreen
          open
          sx={{
            [`& .${dialogClasses.paper}`]: {
              backgroundColor: MPColorValue.BackgroundDefault,
              padding: 0,
            },
            zIndex: 9,
          }}
          onClose={handleClose}
        >
          <DialogTitle
            className={joinClasses(
              CSSGlobal.Flex.RowSpaceBetween,
              CSSGlobal.Flex.Centered,
              styles.dialogTitleOuterContainer
            )}
          >
            <div className={joinClasses(styles.dialogTitleContainer)}>
              <div
                className={joinClasses(
                  CSSGlobal.NoScrollbar,
                  styles.dialogTitleInnerContainer
                )}
              >
                <div
                  className={joinClasses(
                    CSSGlobal.Flex.CenteredRow,
                    CSSGlobal.Flex.NoWrap,
                    CSSGap[20],
                    styles.dialogTitle
                  )}
                >
                  {[
                    ExploreType.Artworks,
                    ExploreType.Exhibitions,
                    ExploreType.Artists,
                    ExploreType.Editorials,
                  ].map((key) => (
                    <ToggleFilter
                      key={key}
                      title={ExploreType[key]}
                      onToggle={() => handleTypeChange(ExploreType[key])}
                      isSelected={exploreType === ExploreType[key]}
                    />
                  ))}
                </div>
              </div>
            </div>
            <MPIconButton
              className={MPBackgroundColorClass.CommonWhite}
              onClick={handleClose}
            >
              <RemoveIcon alt="Close Dialog" />
            </MPIconButton>
          </DialogTitle>
          <MPDivider />
          <DialogContent className={styles.dialogContent}>
            <TrackingContext source={EcommerceSourceType.Explore}>
              {{
                [ExploreType.Artists]: (
                  <ExploreArtists
                    hideHero={hideHero}
                    filters={filters as ArtistsFiltersState}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={handleFiltersReset}
                  />
                ),
                [ExploreType.Artworks]: (
                  <ExploreArtworks
                    hideHero={hideHero}
                    filters={filters as ArtworksFiltersState}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={handleFiltersReset}
                  />
                ),
                [ExploreType.Editorials]: (
                  <ExploreEditorials
                    hideHero={hideHero}
                    filters={filters as EditorialsFiltersState}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={handleFiltersReset}
                  />
                ),
                [ExploreType.Exhibitions]: (
                  <ExploreExhibitions
                    hideHero={hideHero}
                    filters={filters as ExhibitionsFiltersState}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={handleFiltersReset}
                  />
                ),
              }[exploreType] ?? null}
            </TrackingContext>
          </DialogContent>
        </Dialog>
      )}
    </ExploreContext.Provider>
  );
}
