.grid {
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0;
  position: relative;

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }

  @media (--mq-is-mobile) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

.artistCarouselContainer {
  box-sizing: border-box;
  margin: 0 auto 24px auto;
  max-width: 1440px;
  position: relative;

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
  }

  @media (--mq-is-mobile) {
    width: 100vw;
    margin-left: -24px;
  }
}
