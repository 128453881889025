import { useCallback, useContext, useState } from 'react';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';
import useWagmiClient from 'wagmi-client';
import { getAccount } from '@wagmi/core';

import {
  MPColorClass,
  MPDivider,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { MpErrors } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import ConnectorSelector from 'components/wallet/ConnectorSelector';
import { METAMASK_LINK } from 'constants/ExternalUrls';
import ROUTES from 'constants/Routes';
import useWallets from 'hooks/session/useWallets';
import useRegister from 'hooks/wallet/mutations/useRegister';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import isWalletError from 'utils/errors/wallet';

import * as styles from 'css/components/wallet/NewWalletConnectDialog.module.css';

export default function NewWalletConnectDialog({
  open,
  onClose,
  description,
  isCreationEnabled = false,
  onError = noop,
  title = 'Connect New Wallet',
}) {
  const wagmiConfig = useWagmiClient();
  const [_error, _setError] = useState(undefined);
  const setError = useCallback(
    (e) => {
      _setError(e);
      onError(e);
    },
    [_setError, onError]
  );

  const [wallets] = useWallets();

  const [registerWallet] = useRegister();
  const isDjango = useContext(IsDjangoContext);

  const optional_props = isDjango
    ? { container: window.parent.document.body }
    : {};

  const error = isWalletError.RequestPermissionAlreadyPending(_error)
    ? new Error('Please check your wallet provider for pending actions')
    : _error?.name === MpErrors.WalletAssociatedWithAnotherAccount
    ? new Error(
        `It looks like you've already registered this wallet with a different account. You can login into that account with this wallet directly. If you'd rather use this account, please dissociate this wallet from the account settings page of that account.`
      )
    : _error?.name === MpErrors.WalletAlreadyConnected
    ? undefined
    : _error;

  return (
    <MPStandardDialog
      {...optional_props}
      title={title}
      open={open}
      onClose={onClose}
    >
      <>
        <ErrorDisplay error={error} />
        <ConnectorSelector
          title={description}
          onError={setError}
          onConnection={(addresses, connector) => {
            const account = getAccount(wagmiConfig);
            // We reregister the wallet when going from collection to creator, in theory
            // since there is only 1 creation wallet, we'll never run into a conflict.
            if (
              !isCreationEnabled &&
              wallets.find(
                (wallet) =>
                  wallet.address.toLowerCase() ===
                  account.address?.toLowerCase()
              )
            ) {
              onClose();
              return;
            }
            registerWallet(addresses[0], connector, isCreationEnabled)
              .then(onClose)
              .catch(setError);
          }}
        />
        <div className={styles.walletLinkSection}>
          <a
            className={joinClasses('underline', 'invisibleAnchor')}
            href={METAMASK_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Don&#39;t have a wallet?
          </a>
        </div>
        <MPDivider />
        <div
          className={joinClasses(
            MPColorClass.SolidNeutralGray3,
            MPFonts.paragraphSmall,
            styles.termsAndConditions
          )}
        >
          By connecting a wallet you agree to the{' '}
          <Link
            to={ROUTES.TERMS()}
            target="_blank"
            reloadDocument
            className={joinClasses('underline', 'invisibleAnchor')}
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            to={ROUTES.PRIVACY()}
            target="_blank"
            reloadDocument
            className={joinClasses('underline', 'invisibleAnchor')}
          >
            Privacy Policy
          </Link>{' '}
          and agree to receive periodic updates.
        </div>
      </>
    </MPStandardDialog>
  );
}
