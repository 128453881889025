import { useCallback, useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import MPStandardDialog from '../dialog/MPStandardDialog';

import * as styles from '../css/collapsibles/MPExpandableText.module.css';

interface MPExpandableTextProps {
  content: string;
  contentClassName?: string;
  lineClamp?: number;
  moreText?: string;
  title?: string;
}

export default function MPExpandableText({
  content,
  lineClamp = 3,
  title = '',
  moreText = 'View More',
  contentClassName = MPFonts.paragraphNormal,
}: MPExpandableTextProps) {
  const [clampedDiv, setRef] = useRefState<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState<
    [string | number, string | number]
  >(['auto', 'auto']);

  const collapse = useCallback(() => setExpanded(false), []);
  const expand = useCallback(() => setExpanded(true), []);
  const collapseOnEnter = useOnEnterKey(collapse);

  useEffect(() => {
    setClamped(false);
    setExpanded(false);
  }, [content]);

  // Not handling resize as this is fixed width, rechecking on resize also won't work as we wouldn't know the clamped height if resized when expanded.
  useEffect(() => {
    if (clampedDiv) {
      if (clampedDiv.scrollHeight > clampedDiv.clientHeight) {
        setDescriptionMaxHeight([
          clampedDiv.clientHeight,
          clampedDiv.scrollHeight,
        ]);
      }
      setClamped(clampedDiv.scrollHeight > clampedDiv.clientHeight);
    }
  }, [content, clampedDiv]);

  // The typings incorrectly complain on string literals, so not inlining.
  const style = {
    '--line-clamp': lineClamp,
    maxHeight: descriptionMaxHeight[isExpanded ? 1 : 0],
  };

  return (
    <div className={styles.container}>
      <div
        ref={setRef}
        style={style}
        className={joinClasses(styles.content, contentClassName)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      />
      {!!isClamped && (
        <>
          <span
            className={joinClasses(MPFonts.textSmallBold, styles.viewMore)}
            onClick={expand}
            onKeyDown={collapseOnEnter}
            role="button"
            tabIndex={0}
          >
            {moreText}
          </span>
          <MPStandardDialog title={title} open={isExpanded} onClose={collapse}>
            <div className={MPFonts.textSmallRegular}>{content}</div>
          </MPStandardDialog>
        </>
      )}
    </div>
  );
}
